import { useEffect, useRef } from "react";

export function useEventListener<K extends keyof DocumentEventMap>(type: K, handler: (evt: DocumentEventMap[K]) => any, element: Window | Document | Element = window, options?: boolean | AddEventListenerOptions) {
  // Create a ref that stores handler
  const savedHandler = useRef<(evt: DocumentEventMap[K]) => any>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  useEffect(
    () => {
      // Make sure element supports addEventListener
      // On 
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      // @ts-ignore
      const eventListener = event => savedHandler.current(event);

      // Add event listener
      document.addEventListener(type, eventListener, options);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(type, eventListener, options);
      };
    },
    [type, element, options] // Re-run if eventName or element changes
  );
}
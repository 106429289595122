import React, { FC } from 'react';
import { Button, Phone } from 'primitive-comps';
import moment from 'moment';
import { IProviderAppointment, AppointmentStatusEnum } from '../../Store/Appointment/types';
import { Config } from '../../config';
import { Name } from '../Name';
import { DATE_TIME_FORMAT } from '../Util/moment';
import { ResendEmail } from '../ResendEmail';
import { Timeline } from './Timeline';
import './AppointmentItem.css';

interface IProps {
  isAdmin?: boolean;
  appointment: IProviderAppointment;
  onClick: (apt: IProviderAppointment) => void;
}
export const AppointmentItem: FC<IProps> = ({ appointment, onClick, isAdmin }) => {
  const { member, date, appointmentId, status, memberTimeline, providerTimeline } = appointment;

  return (
    <div className="appointment-item">
      <Button type="link" className="appointment-item-link" onClick={() => onClick(appointment)}>
        <div className="appointment-item-member-name"><Name firstName={member.firstname} lastName={member.lastname} /></div>
      </Button>
      <div className="appointment-item-date">{moment(date).format(DATE_TIME_FORMAT)}</div>
      <div className="appointment-item-info">
        <div style={{ flex: 1, padding: 10 }}>
          {status === AppointmentStatusEnum.Cancelled && <div className="cancelled-status">Cancelled</div>}
          <div className="appointment-item-info-item">
            <span className="appointment-item-label">Member Email:</span><span className="appointment-item-value">{member.email}</span>
          </div>
          <div className="appointment-item-info-item">
            <span className="appointment-item-label">Member Phone:</span><span className="appointment-item-value"><Phone phone={member.phone} /></span>
          </div>
          <div className="appointment-item-info-item">
            <span className="appointment-item-label">Member Link:</span><span className="appointment-item-value appointment-item-member-link">{Config.Host + '/member?id=' + appointmentId}</span>
          </div>
          {isAdmin && memberTimeline && memberTimeline.length > 0 &&
            <>
              <div className="appointment-item-label">Member:</div>
              <div className="appointment-timeline">
                <Timeline timelines={memberTimeline} />
              </div>
              <br />
              <div className="appointment-item-label">Provider:</div>
              <div className="appointment-timeline">
                <Timeline timelines={providerTimeline} />
              </div>
            </>
          }
        </div>
        {isAdmin &&
          <div className="appointment-item-resend-email">
            <ResendEmail defaultEmail={member.email} lastName={member.lastname} link={Config.Host + '/member?id=' + appointmentId} />
          </div>
        }
      </div>
    </div>
  );
};
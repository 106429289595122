import React, { FC, useState } from 'react';
import { PageContainer } from '../../Components/PageContainer';
import { Button, DatePicker } from 'primitive-comps';
import { useNoAuthRedirect } from '../../Hooks/UseNoAuthRedirect';
import './styles.css';
import { Container } from '../../Components/Container';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { DATE_FORMAT } from '../../Components/Util/moment';
import { stringify } from 'query-string';
import { IAppointmentPageQueries } from '../Appointments';
import { ProvidersSelect } from '../../Components/ProvidersSelect';
import { TempRoomLink } from '../../Components/TempRoomLink';
import { GenerateRoomButton } from '../../Components/GenerateRoomButton';

export const Admin: FC = () => {
  const { canAccess } = useNoAuthRedirect(true);
  const [providerId, setProviderId] = useState<string>();
  const [startTime, setStartTime] = useState<string>();
  const [endTime, setEndTime] = useState<string>();
  const { push } = useHistory();
  const handleClick = () => {
    if (!providerId) {
      return;
    }
    const queries: IAppointmentPageQueries = {};
    if (startTime) {
      queries.startTime = startTime;
    }
    if (endTime) {
      queries.endTime = endTime;
    }

    const queryString = stringify(queries);
    push(`/appointments/${providerId.trim()}` + (queryString ? `?${queryString}` : ''));
  };

  if (!canAccess) { return null; }
  return (
    <Container contentFit>
      <PageContainer title="Admins">
        <div className="provider-form">
          <div>
            Provider Email:&nbsp;
            <ProvidersSelect value={providerId} onChange={(value) => setProviderId(value || undefined)} />
          </div>
          <div>
            Start Date:&nbsp;
            <DatePicker
              defaultValue={moment().startOf('day')}
              value={startTime ? moment(+startTime) : undefined}
              onChange={(value) => setStartTime(value ? value.startOf('day').toDate().getTime().toString() : undefined)}
              format={DATE_FORMAT}
            />
          </div>
          <div>
            End Date:&nbsp;
            <DatePicker
              defaultValue={moment().endOf('day')}
              value={endTime ? moment(+endTime) : undefined}
              onChange={(value) => setEndTime(value ? value.endOf('day').toDate().getTime().toString() : undefined)}
              format={DATE_FORMAT}
            />
          </div>
          <div>
            &nbsp;
            <Button className="button" onClick={handleClick}>Get Appointments</Button>
          </div>
        </div>
        <div className="temp-room-container">
          <GenerateRoomButton />
          <TempRoomLink />
        </div>
      </PageContainer>
    </Container>
  );
};
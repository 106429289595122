import React, { FC } from 'react';
import { getName } from '../Util/name';

interface BaseProps {
  firstName?: string;
  middleName?: string | null;
  lastName?: string;
}
interface IProps extends BaseProps { }

export const Name: FC<IProps> = (props) => {
  return (
    <>{getName(props)}</>
  );
};

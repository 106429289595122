import { createReducer } from '@reduxjs/toolkit';
import { memberAppointmentActions, memberAppointmentEpicActions } from './actions';
import { IMemberAppointment } from './types';

export interface IMemberAppointmentState {
  loading: boolean;
  data?: IMemberAppointment;
  error?: string;
}
const initialState: IMemberAppointmentState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const memberAppointmentReducer = createReducer(initialState, builder =>
  builder
    .addCase(memberAppointmentActions.get, (state, action) => ({
      loading: true,
      data: undefined,
      error: undefined,
    }))
    .addCase(memberAppointmentEpicActions.getSucc, (state, action) => ({
      loading: false,
      data: action.payload.appointment,
      error: undefined,
    }))
    .addCase(memberAppointmentEpicActions.getFail, (state, action) => ({
      loading: false,
      data: state.data,
      error: action.payload.error,
    }))
);

import * as React from 'react';
import { Input } from 'antd';
import { map } from 'lodash';
import { IVidyoConnector } from '../../Vidyo';
import { Chat } from './chat';

const ENTER_KEY = 13;

const LOG_STYLE: React.CSSProperties = {
  padding: 10,
  overflow: 'auto',
  paddingBottom: 50,
};

interface IProps {
  vidyoConnector: IVidyoConnector;
  userName: string;
  disabled?: boolean;
}

interface IState {
  initialized: boolean;
  currChatMessage: string;
  chat: Array<{ user: string, message: string }>;
}

export class VidyoChat extends React.Component<IProps, IState> {
  chatBox: HTMLDivElement | null = null;
  state: IState = {
    initialized: false,
    currChatMessage: '',
    chat: [],
  };

  componentDidMount() {
    this.registerMessageEventListener();
  }

  componentWillUnmount() {
    this.props.vidyoConnector.UnregisterMessageEventListener();
  }

  scrollBottom = () => {
    if (this.chatBox) {
      this.chatBox.scrollTop = this.chatBox.scrollHeight;
    }
  }

  registerMessageEventListener = async () => {
    try {
      const { userName } = this.props;
      const status = await this.props.vidyoConnector.RegisterMessageEventListener({
        onChatMessageReceived: (participant, messageObj) => {
          console.log(participant, messageObj);
          const { chat: oldChat } = this.state;
          const chat = [...oldChat, { user: userName === participant.name ? 'Me' : participant.name, message: messageObj.body }];
          this.setState({ chat }, this.scrollBottom);
        }
      });
      if (status) {
        this.setState({ initialized: true });
      }
    } catch (error) {
      console.error(error);
    }
  }


  handleChat = (e: any) => {
    this.setState({ currChatMessage: e.target.value });
  }

  sendMessage = async () => {
    try {
      const { currChatMessage } = this.state;
      const status = await this.props.vidyoConnector.SendChatMessage({ message: currChatMessage });
      if (status) {
        console.log('chat sent!');
        this.setState({ currChatMessage: '' });
      }
    } catch (error) {
      console.error(error);
    }
  }

  handleChatKeyUp = (e: any) => {
    const charCode = (typeof e.which === 'number') ? e.which : e.keyCode;
    if (charCode === ENTER_KEY && this.state.currChatMessage !== '') {
      this.sendMessage();
    }
  }

  render() {
    const { chat, currChatMessage, initialized } = this.state;

    if (!initialized) {
      return null;
    }
    return (
      <div className="vidyo-chat">
        <div ref={node => this.chatBox = node} style={LOG_STYLE}>
          <h1>Chat</h1>
          {map(chat, ({ user, message }, i, arr) => (
            <Chat
              key={i}
              user={user}
              message={message}
              isSelf={user === 'Me'}
              // if the user is not self and different from the previous sender
              showSenderName={user !== 'Me' && (i < 1 || arr[i - 1].user !== user)}
            />))}
        </div>
        <div style={{ position: 'absolute', bottom: 0, width: '100%' }}>
          <Input
            disabled={this.props.disabled}
            placeholder="Send a message"
            value={currChatMessage}
            onChange={this.handleChat}
            onKeyUp={this.handleChatKeyUp}
          />
        </div>
      </div>
    );
  }
}
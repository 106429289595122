import React, { FC, useState } from 'react';
import { Button } from 'primitive-comps';
import { Timeline as TimelineBase, TimelineItem } from '../Timeline';
import { TTimeline } from '../../Store/Appointment/types';

interface IProps {
  timelines: TTimeline[];
}
export const Timeline: FC<IProps> = ({ timelines }) => {
  const [isDetailedView, setDetailedView] = useState(false);

  function renderTimeline() {
    return (
      <div style={{ marginTop: 60 }}>
        <TimelineBase>
          {timelines.map((t, i) =>
            <TimelineItem key={i} time={t[0]} dotColor={t[1] === 'JOIN' ? 'green' : 'red'} reverse={t[1] !== 'JOIN'}>
              {t[1] === 'JOIN' ? 'JOINED' : 'LEFT'}
            </TimelineItem>
          )}
        </TimelineBase>
      </div>
    );
  }

  function renderDetailed() {
    return (
      <div>
        {timelines.map((t, i) =>
          <TimelineItem key={i} time={t[0]}>
            {t[1] === 'JOIN' ? <span style={{ color: 'green' }}>JOINED</span> : <span style={{ color: 'red' }}>LEFT</span>}
          </TimelineItem>
        )}
      </div>
    );
  }

  return (
    <div>
      <Button type="link" onClick={() => setDetailedView(!isDetailedView)}>Change View</Button>
      <div>
        {isDetailedView
          ? <>{renderDetailed()}</>
          : <>{renderTimeline()}</>
        }
      </div>
    </div>
  );
};
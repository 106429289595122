import React, { FC, useEffect } from 'react';
import { CenterContainer } from 'primitive-comps';
import { PageContainer } from '../../Components/PageContainer';
import { LoginForm } from '../../Components/LoginForm';
import { Container } from '../../Components/Container';

export const Home: FC = () => {
  useEffect(() => { document.title = 'TeleHealth'; }, []);

  return (
    <Container contentFit>
      <PageContainer title="TeleHealth">
        <CenterContainer>
          <LoginForm />
        </CenterContainer>
      </PageContainer>
    </Container>
  );
};

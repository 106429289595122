import { createAction } from '@reduxjs/toolkit';
import {
  IMemberJoinOrLeavePayload,
  IMemberJoinOrLeaveSucc,
  IMemberJoinOrLeaveFail,
  IProviderJoinOrLeavePayload,
  IProviderJoinOrLeaveSucc,
  IProviderJoinOrLeaveFail,
} from './types';


export const joinOrLeaveActions = {
  sendMember: createAction<IMemberJoinOrLeavePayload, 'JOIN_OR_LEAVE_MEMBER'>('JOIN_OR_LEAVE_MEMBER'),
  sendProvider: createAction<IProviderJoinOrLeavePayload, 'JOIN_OR_LEAVE_PROVIDER'>('JOIN_OR_LEAVE_PROVIDER'),
};

export const joinOrLeaveEpicActions = {
  sendMemberSucc: createAction<IMemberJoinOrLeaveSucc, 'JOIN_OR_LEAVE_MEMBER_SUCC'>('JOIN_OR_LEAVE_MEMBER_SUCC'),
  sendMemberFail: createAction<IMemberJoinOrLeaveFail, 'JOIN_OR_LEAVE_MEMBER_FAIL'>('JOIN_OR_LEAVE_MEMBER_FAIL'),
  sendProviderSucc: createAction<IProviderJoinOrLeaveSucc, 'JOIN_OR_LEAVE_PROVIDER_SUCC'>('JOIN_OR_LEAVE_PROVIDER_SUCC'),
  sendProviderFail: createAction<IProviderJoinOrLeaveFail, 'JOIN_OR_LEAVE_PROVIDER_FAIL'>('JOIN_OR_LEAVE_PROVIDER_FAIL'),
};

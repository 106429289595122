import { createAction } from '@reduxjs/toolkit';
import { IGetProviderAppointmetnsSucc, IGetProviderAppointmentsFail, IGetProviderAppointmentsActionPayload } from './types';

export const appointmentActions = {
  getProviderAppointments: createAction<IGetProviderAppointmentsActionPayload | undefined, 'GET_PROVIDER_APPOINTMENTS'>('GET_PROVIDER_APPOINTMENTS'),
  getMoreProviderAppointments: createAction('GET_MORE_PROVIDER_APPOITNMENTS'),
};

export const appointmentEpicActions = {
  getProviderAppointmentsSucc: createAction<IGetProviderAppointmetnsSucc, 'GET_PROVIDER_APPOINTMENTS_SUCC'>('GET_PROVIDER_APPOINTMENTS_SUCC'),
  getProviderAppointmentsFail: createAction<IGetProviderAppointmentsFail, 'GET_PROVIDER_APPOINTMENTS_FAIL'>('GET_PROVIDER_APPOINTMENTS_FAIL'),
  getMoreProviderAppointmentsSucc: createAction<IGetProviderAppointmetnsSucc, 'GET_PROVIDER_MORE_APPOINTMENTS_SUCC'>('GET_PROVIDER_MORE_APPOINTMENTS_SUCC'),
  getMoreProviderAppointmentsFail: createAction<IGetProviderAppointmentsFail, 'GET_PROVIDER_MORE_APPOINTMENTS_FAIL'>('GET_PROVIDER_MORE_APPOINTMENTS_FAIL'),
};

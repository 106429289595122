import { createReducer } from '@reduxjs/toolkit';
import { joinOrLeaveActions, joinOrLeaveEpicActions } from './actions';

export interface IMemberLeaveOrJoinState {
  loading: boolean;
  success?: boolean;
  error?: string;
}
const initialState: IMemberLeaveOrJoinState = {
  loading: false,
  success: undefined,
  error: undefined,
};

export const memberJoinOrLeaveReducer = createReducer(initialState, builder =>
  builder
    .addCase(joinOrLeaveActions.sendMember, (state, action) => ({
      loading: true,
      success: undefined,
      error: undefined,
    }))
    .addCase(joinOrLeaveEpicActions.sendMemberSucc, (state, action) => ({
      loading: false,
      success: true,
      error: undefined,
    }))
    .addCase(joinOrLeaveEpicActions.sendMemberFail, (state, action) => ({
      loading: false,
      success: false,
      error: action.payload.error,
    }))
);

export const providerJoinOrLeaveReducer = createReducer(initialState, builder =>
  builder
    .addCase(joinOrLeaveActions.sendProvider, (state, action) => ({
      loading: true,
      success: undefined,
      error: undefined,
    }))
    .addCase(joinOrLeaveEpicActions.sendProviderSucc, (state, action) => ({
      loading: false,
      success: true,
      error: undefined,
    }))
    .addCase(joinOrLeaveEpicActions.sendProviderFail, (state, action) => ({
      loading: false,
      success: false,
      error: action.payload.error,
    }))
);

import React, { FC, useState } from 'react';
import { Button, Input, Alert } from 'primitive-comps';
import { useAjaxPost } from '../../Hooks';
import { Config } from '../../config';
import { EmailRequestPayload, createEmailTemplate } from '../../Utils/Notification';
import './styles.css';

interface IProps {
  defaultEmail?: string;
  lastName: string;
  link: string;
}
export const ResendEmail: FC<IProps> = ({ defaultEmail, lastName, link }) => {
  const [email, setEmail] = useState(defaultEmail || '');
  const [hasClickedResend, sendHasClickedResend] = useState(false);

  const request = useAjaxPost({
    url: Config.NotificationApi + '/email/sendEmail',
    body: createEmailBody(email, lastName, link),
    headers: { 'Authorization': Config.NotificationApiKey, 'Content-Type': 'application/json' }
  });


  return (
    <div className="resend-email-container">
      {hasClickedResend
        ? <>
          {(request.data || request.error) &&
            <>
              <Alert
                type={request.data ? 'success' : 'error'}
                message={request.data && !request.error ? 'Email was sent successfully!' : `Error sending email! ${request.error!.message}`}
              />
              &nbsp;
            </>
          }
          <Input className="resend-email-input" value={email} placeholder="Email" onChange={(evt) => setEmail(evt.target.value)} />
          <Button className="button" onClick={request.send} disabled={request.isLoading} loading={request.isLoading}>Send</Button>
        </>
        : <Button className="button" onClick={() => sendHasClickedResend(true)}>Resend Email</Button>
      }
    </div>
  );
};

function createEmailBody(email: string, LastName: string, link: string): EmailRequestPayload {
  return {
    ToAddress: [email],
    Subject: 'TeleHealth Appointment',
    LastName,
    Content: createEmailTemplate(link),
    TextFormat: 'html',
    FromAddress: Config.SenderEmail,
  };
}
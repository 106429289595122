import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { UserName } from '../UserName';
import { RootState } from '../../Store';
import { LogoutButton } from '../LogoutButton';
import logo from './logo.png';
import './styles.css';
import { TechnicalSupport } from '../TechnicalSupport';
import { Dropdown } from '../Dropdown';
import { useWindowSize } from '../../Hooks';
import { Config } from '../../config';

const WIDTH_THRESHOLD = 600;

export const Header: FC = () => {
  const isLoggedIn = useSelector((state: RootState) => !!state.auth.data);
  const isAdmin = useSelector((state: RootState) => state.auth.data && state.auth.data.user.isAdmin);
  const width = useWindowSize().width || 0;

  return (
    <header className="header">
      {width > WIDTH_THRESHOLD && <img className="logo" src={logo} alt="Home" />}
      <div className="technical-support"><TechnicalSupport /></div>
      {!Config.IsProd && <h1 style={{ color: 'red', margin: 0 }}>This NOT Production</h1>}
      {isLoggedIn &&
        <div className="right-section">
          {width > WIDTH_THRESHOLD && <h3 className="name">
            <UserName />
          </h3>}
          <Dropdown>
            {isAdmin
              ? <Link className="header-dropdown-button" to="/admin">Admin</Link>
              : <Link className="header-dropdown-button" to="/appointments">Appointments</Link>
            }
            <LogoutButton className="header-dropdown-button">Sign Out</LogoutButton>
          </Dropdown>
        </div>
      }
    </header>
  );
};
import { ofType } from 'redux-observable';
import { SUBSCRIBE_ERRORS, UNSUBSCRIBE_ERRORS } from './types';
import { map, takeUntil, switchMap } from 'rxjs/operators';
import { errorStatussesObs } from '../Epics/helpers';
import { errorEpicActions } from './actions';
import { Epic } from '../Epics/EpicType';

export const errorEpic: Epic = (action$, state$) => action$.pipe(
  ofType(SUBSCRIBE_ERRORS),
  switchMap(() =>
    errorStatussesObs.pipe(
      map(errorEpicActions.set),
      takeUntil(action$.ofType(UNSUBSCRIBE_ERRORS))
    )
  )
);

import React, { FC } from 'react';
import { IProviderAppointment } from '../../Store/Appointment/types';
import { AppointmentItem } from './AppointmentItem';
import './styles.css';
import { Empty } from 'primitive-comps';
import { Center } from '../Center';

interface IProps {
  isAdmin?: boolean;
  appointments: IProviderAppointment[];
  onClick: (apt: IProviderAppointment) => void;
}
export const AppointmentsList: FC<IProps> = ({ appointments, onClick, isAdmin }) => {
  if (appointments.length === 0) {
    return (
      <Center>
        <Empty description="You have no appointments for today!" />
      </Center>
    );
  }

  return (
    <div className="appointment-list">
      {appointments.map((apt, i) => <AppointmentItem key={i} appointment={apt} onClick={onClick} isAdmin={isAdmin} />)}
    </div>
  );
};
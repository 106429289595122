export interface IGetProviderAppointmentsActionPayload {
  startTime?: number;
  endTime?: number;
  limit?: number;
  providerId?: string;
}

export interface IGetProviderAppointmetnsSucc {
  appointments: IProviderAppointment[];
  provider: IProviderInfo;
  hasMore: boolean;
}

export interface IGetProviderAppointmentsFail {
  error: any;
}

export enum AppointmentStatusEnum {
  Pending = 'Pending',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}

export interface IProviderAppointment {
  date: string,
  resourceId: string;
  token: string;
  member: {
    id: string;
    email: string;
    firstname: string;
    lastname: string;
    phone: string;
  },
  appointmentId: string;
  status: AppointmentStatusEnum;
  memberTimeline: TTimeline[];
  providerTimeline: TTimeline[];
}

export interface IGetProviderAppointmentsPayload {
  startTime: number;
  endTime: number;
  offset: number;
  limit: number;
  providerId?: string;
}

export interface IProviderInfo {
  id: string;
  firstname: string;
  lastname: string;
}

export type TTimeline = [string | number, 'JOIN' | 'LEAVE'];
import { filter, switchMap, map, catchError, timeout, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ajax } from '../Epics/helpers';
import { Epic } from '../Epics/EpicType';
import { generateRoomEpicActions, generateRoomActions } from './actions';
import { Config } from '../../config';
import { IGetGenerateRoomSucc } from './types';

const TIMEOUT = 15000;

export const generateRoomEpic: Epic = (action$, state$) => action$.pipe(
  filter(generateRoomActions.get.match),
  withLatestFrom(state$),
  switchMap(([_, state]) =>
    ajax.post<IGetGenerateRoomSucc>(
      Config.ServerUrl + '/generateRoom',
      {},
      { authorization: 'Bearer ' + state.auth.data!.token }
    ).pipe(
      timeout(TIMEOUT),
      map((res) => generateRoomEpicActions.getSucc(res.response)),
      catchError((error) => of(generateRoomEpicActions.getFail({ error: (error.response ? error.response.error : error.message) || 'Something went wrong!' })))
    )
  )
);

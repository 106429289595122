import moment from "moment";
import { DATE_TIME_WITH_TIMEZONE_FORMAT } from './moment';
import { IProviderAppointment } from "../../Store/Appointment/types";
import { IProviderInfo } from '../../Store/Appointment/types';

export type TTimeline = [string, string, string, string, string, 'JOIN' | 'LEAVE']; // serviceId, appointmentId, scheduled date, date, provider/member name, 'JOIN/LEAVE' 

const merge = ({ providerTimeline = [], memberTimeline = [], appointmentId, member, date }: IProviderAppointment, providerInfo: IProviderInfo) => {
  let mIndex = 0;
  let pIndex = 0;
  const result: TTimeline[] = [];
  const d = moment(date).format(DATE_TIME_WITH_TIMEZONE_FORMAT);

  while (pIndex < providerTimeline.length || mIndex < memberTimeline.length) {
    if (mIndex >= memberTimeline.length || (pIndex < providerTimeline.length && +providerTimeline[pIndex][0] <= +memberTimeline[mIndex][0])) {
      result.push([
        member.id,
        appointmentId,
        d,
        moment(+providerTimeline[pIndex][0]).format(DATE_TIME_WITH_TIMEZONE_FORMAT),
        providerInfo.firstname + ' ' + providerInfo.lastname,
        providerTimeline[pIndex][1]
      ]);
      pIndex++;
    } else {
      result.push([
        member.id,
        appointmentId,
        d,
        moment(+memberTimeline[mIndex][0]).format(DATE_TIME_WITH_TIMEZONE_FORMAT),
        member.firstname + ' ' + member.lastname,
        memberTimeline[mIndex][1]
      ]);
      mIndex++;
    }
  }

  return result;
};

export const getAllAppointmetsTimelines = (appointments: IProviderAppointment[], providerInfo: IProviderInfo) => {
  let result: TTimeline[] = [];
  appointments.forEach(a => {
    if ((a.memberTimeline && a.memberTimeline.length > 0) || (a.providerTimeline && a.providerTimeline.length > 0)) {
      result = result.concat(merge(a, providerInfo));
    }
  });
  return result;
};

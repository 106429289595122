import { filter, switchMap, map, catchError, timeout } from 'rxjs/operators';
import { of } from 'rxjs';
import { ajax } from '../Epics/helpers';
import { Epic } from '../Epics/EpicType';
import { memberAppointmentActions, memberAppointmentEpicActions } from './actions';
import { Config } from '../../config';
import { IGetMemberAppointmentSucc } from './types';

const TIMEOUT = 15000;

export const getMemberAppointmentEpic: Epic = (action$, state$) => action$.pipe(
  filter(memberAppointmentActions.get.match),
  switchMap(action =>
    ajax.get<IGetMemberAppointmentSucc>(Config.ServerUrl + '/getMemberAppointment', { ...action.payload }).pipe(
      timeout(TIMEOUT),
      map(({ response }) => memberAppointmentEpicActions.getSucc(response)),
      catchError((error) => of(memberAppointmentEpicActions.getFail({ error: error.response ? error.response.error : error.message })))
    )
  )
);

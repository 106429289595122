import { filter, switchMap, map, catchError, timeout, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ajax } from '../Epics/helpers';
import { Epic } from '../Epics/EpicType';
import { joinOrLeaveActions, joinOrLeaveEpicActions } from './actions';
import { Config } from '../../config';
import { IMemberJoinOrLeaveSucc, IProviderJoinOrLeaveSucc } from './types';

const TIMEOUT = 15000;

export const memberJoinOrLeaveEpic: Epic = (action$, state$) => action$.pipe(
  filter(joinOrLeaveActions.sendMember.match),
  switchMap(action =>
    ajax.post<IMemberJoinOrLeaveSucc>(Config.ServerUrl + '/memberJoinedOrLeft', { ...action.payload }).pipe(
      map(({ response }) => joinOrLeaveEpicActions.sendMemberSucc(response)),
      catchError((error) => of(joinOrLeaveEpicActions.sendMemberFail({ error: (error.response ? error.response.error : error.message) || 'Something went wrong!' })))
    )
  )
);

export const providerJoinOrLeaveEpic: Epic = (action$, state$) => action$.pipe(
  filter(joinOrLeaveActions.sendProvider.match),
  withLatestFrom(state$),
  switchMap(([action, state]) =>
    ajax.post<IProviderJoinOrLeaveSucc>(Config.ServerUrl + '/providerJoinedOrLeft', { ...action.payload }, { authorization: 'Bearer ' + state.auth.data!.token }).pipe(
      timeout(TIMEOUT),
      map(({ response }) => joinOrLeaveEpicActions.sendProviderSucc(response)),
      catchError((error) => of(joinOrLeaveEpicActions.sendProviderFail({ error: (error.response ? error.response.error : error.message) || 'Something went wrong!' })))
    )
  )
);
import { createAction } from '@reduxjs/toolkit';
import { IGetMemberAppointmentSucc, IGetMemberAppointmentFail, IGetMemberAppointmentPayload } from './types';


export const memberAppointmentActions = {
  get: createAction<IGetMemberAppointmentPayload, 'GET_MEMEBER_APPOINTMENT'>('GET_MEMEBER_APPOINTMENT'),
};

export const memberAppointmentEpicActions = {
  getSucc: createAction<IGetMemberAppointmentSucc, 'GET_MEMBER_APPOINTMENT_SUCC'>('GET_MEMBER_APPOINTMENT_SUCC'),
  getFail: createAction<IGetMemberAppointmentFail, 'GET_MEMBER_APPOINTMENT_FAIL'>('GET_MEMBER_APPOINTMENT_FAIL'),
};

import { isEmpty } from 'lodash';
import { IVC, IVidyoConnector, IConnectorOptions } from './tsType';

export * from './tsType';

export function getVidyoClient(): Promise<IVC> {
  return new Promise(resolve => {
    // @ts-ignore
    const vc = window['VC'];
    if (vc && !isEmpty(vc)) {
      resolve(vc);
    } else {
      document.addEventListener('VidyoClient:ready', (e: any) => {
        resolve(e.detail);
      });
    }
  });
}

const delay = (delayMs: number) => new Promise<undefined>(resolve => {
  setTimeout(() => resolve(), delayMs);
});

export async function createVidyoConnector(VC: IVC, options: IConnectorOptions, maxRetries: number = 10): Promise<IVidyoConnector> {
  async function helper(retry = 0): Promise<IVidyoConnector> {
    if (retry >= maxRetries) {
      throw new Error('Failed to create vidyo connector.');
    }
    try {
      const attemptTimeoutPromise = delay(20000).then(() => void 0);
      const connectorPromise = VC.CreateVidyoConnector(options);
      const connector = await Promise.race([attemptTimeoutPromise, connectorPromise]);
      if (!connector) {
        throw new Error('attempt timed out...');
      }
      return connector;
    }
    catch (err) {
      console.error(err);
      const retryDelaySeconds = Math.pow(2, retry) + Math.random();
      console.log(`Failed to create vidyo connector. Retrying in ${retryDelaySeconds} seconds...`);
      await delay(retryDelaySeconds * 1000);
      return await helper(retry + 1);
    }
  }

  return helper();
}

import React, { FC, ReactNode, useCallback, useState } from 'react';
import moment from 'moment';
import './styles.css';
import { TIME_WITH_SEC_FORMAT, TIME_FORMAT } from '../Util/moment';

interface ITimelineProps {
  children: React.ReactElement<ITimelineItemProps>[];
}
export const Timeline: FC<ITimelineProps> = ({ children }) => {
  const [hoverIndex, setHoverIndex] = useState<number>();

  const getPos = useCallback((time: number) => {
    const len = children.length;
    if (len === 0) {
      return '0%';
    }
    if (len === 1) {
      return '50%';
    }
    const start = +children[0].props.time;
    const total = +children[len - 1].props.time - start;
    return '' + Math.round(((time - start) * 100) / total) + '%';
  }, [children]);

  return (
    <div className="timeline-container">
      <div className="timeline">
        {React.Children.map(children, (child, i) => {
          const left = getPos(+child.props.time);
          return (
            <div key={i} className="timeline-item" style={{ left, backgroundColor: child.props.dotColor }} onMouseOver={() => setHoverIndex(i)} >
              <div className={"timeline-info" + (hoverIndex === i ? ' show' : '') + (i === 0 ? ' first' : '') + (i === children.length - 1 ? ' last' : '') + (child.props.reverse ? ' reverse' : '')}>
                {moment(+child.props.time).format(TIME_WITH_SEC_FORMAT)}
                <div className="timeline-description">
                  {child.props.children}
                </div>
              </div>
            </div>
          );
        }
        )}
      </div>
    </div>
  );
};


interface ITimelineItemProps {
  time: number | string;
  children?: ReactNode;
  dotColor?: string;
  reverse?: boolean;
}
export const TimelineItem: FC<ITimelineItemProps> = ({ time, children }) => {
  return (
    <div>{moment(+time).format(TIME_FORMAT)}: {children}</div>
  );
};


import { createReducer } from '@reduxjs/toolkit';
import { appointmentActions, appointmentEpicActions } from './actions';
import { IProviderAppointment, IGetProviderAppointmentsPayload, IProviderInfo } from './types';
import moment from 'moment';

const LIMIT = 50;

export interface IProviderAppointmentsState {
  loading: boolean;
  loadingMore: boolean;
  data?: {
    appointments: IProviderAppointment[];
    provider: IProviderInfo;
  };
  error?: string;
  hasMore: boolean;
  payload: IGetProviderAppointmentsPayload;
}
const initialState: IProviderAppointmentsState = {
  loading: false,
  loadingMore: false,
  data: undefined,
  error: undefined,
  hasMore: false,
  payload: {
    startTime: moment().startOf('day').toDate().getTime(),
    endTime: moment().endOf('day').toDate().getTime(),
    offset: 0,
    limit: LIMIT,
    providerId: undefined,
  },
};

export const providerAppointmentsReducer = createReducer(initialState, builder =>
  builder
    // GET
    .addCase(appointmentActions.getProviderAppointments, (state, action) => ({
      loading: true,
      loadingMore: false,
      data: undefined,
      error: undefined,
      hasMore: false,
      payload: action.payload
        ? {
          startTime: action.payload.startTime || moment().startOf('day').toDate().getTime(),
          endTime: action.payload.endTime || moment().endOf('day').toDate().getTime(),
          offset: 0,
          limit: action.payload.limit || state.payload.limit,
          providerId: action.payload.providerId || state.payload.providerId,
        }
        : state.payload
    }))
    .addCase(appointmentEpicActions.getProviderAppointmentsSucc, (state, action) => ({
      loading: false,
      loadingMore: false,
      data: {
        appointments: action.payload.appointments,
        provider: action.payload.provider,
      },
      error: undefined,
      hasMore: action.payload.hasMore,
      payload: state.payload,
    }))
    .addCase(appointmentEpicActions.getProviderAppointmentsFail, (state, action) => ({
      loading: false,
      loadingMore: false,
      data: state.data,
      error: action.payload.error,
      hasMore: state.hasMore,
      payload: state.payload,
    }))
    // GET MORE
    .addCase(appointmentActions.getMoreProviderAppointments, (state, action) => ({
      loading: false,
      loadingMore: true,
      data: state.data,
      error: undefined,
      hasMore: false,
      payload: { ...state.payload, offset: state.payload ? state.payload.offset + state.payload.limit : 0 }
    }))
    .addCase(appointmentEpicActions.getMoreProviderAppointmentsSucc, (state, action) => ({
      loading: false,
      loadingMore: false,
      data: {
        appointments: ((state.data && state.data.appointments) || []).concat(action.payload.appointments),
        provider: action.payload.provider,
      },
      error: undefined,
      hasMore: action.payload.hasMore,
      payload: state.payload,
    }))
    .addCase(appointmentEpicActions.getMoreProviderAppointmentsFail, (state, action) => ({
      loading: false,
      loadingMore: false,
      data: state.data,
      error: action.payload.error,
      hasMore: state.hasMore,
      payload: { ...state.payload, offset: state.payload ? state.payload.offset - state.payload.limit : 0 },
    }))
);

import React, { FC } from 'react';
import { Container } from '../Container';
import './styles.css';

interface IProps {
  center?: boolean;
}
export const Loading: FC<IProps> = ({ center }) =>
  center
    ? (
      <Container withoutHeader center>
        <div className="loading">
          <div></div>
          <div></div>
          <div></div>
        </div>
      </Container>
    )
    : (
      <div className="loading">
        <div></div>
        <div></div>
        <div></div>
      </div>
    );
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from '../../Store/';

export function useNoAuthRedirect(shouldBeAdmin: boolean = false) {
  const isLoggedIn = useSelector((state: RootState) => !!state.auth.data);
  const isAdmin = useSelector((state: RootState) => state.auth.data && state.auth.data.user.isAdmin);
  const canAccess = isLoggedIn && (!shouldBeAdmin || isAdmin);

  const { push } = useHistory();
  if (!canAccess) {
    push('/login-form');
  }
  return { isLoggedIn, isAdmin, canAccess };
}
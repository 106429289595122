import React, { Component } from 'react';
import { Modal, Button, Dropdown, Icon, Menu, Form } from 'antd';
import { ILocalDevices, ILocalCamera, ILocalMicrophone, ILocalSpeaker, ILocalDevice } from '../../Vidyo';
import { map } from 'lodash';
import './styles.css';

interface IProps {
  cameraOn: boolean;
  callOn: boolean;
  microphoneOn: boolean;
  toggleCamera: () => void;
  toggleCall: () => void;
  toggleMicrophone: () => void;
  toggleChat: () => void;
  selectedCamera?: string;
  selectedMicrophone?: string;
  selectedSpeaker?: string;
  cameras: ILocalDevices<ILocalCamera>;
  microphones: ILocalDevices<ILocalMicrophone>;
  speakers: ILocalDevices<ILocalSpeaker>;
  changeCamera: (device: ILocalCamera) => void;
  changeMicrophone: (device: ILocalMicrophone) => void;
  changeSpeaker: (device: ILocalSpeaker) => void;
  isMobile: boolean;
}
interface IState {
  modalVisible: boolean;
  selectedCamera: ILocalCamera | null;
  selectedMicrophone: ILocalMicrophone | null;
  selectedSpeaker: ILocalSpeaker | null;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

export class VidyoToolbar extends Component<IProps, IState> {
  state: IState = {
    modalVisible: false,
    selectedCamera: null,
    selectedMicrophone: null,
    selectedSpeaker: null,
  };

  get cameraStyle() {
    return 'toolbarButton ' + (this.props.cameraOn ? 'cameraOn' : 'cameraOff');
  }
  get callStyle() {
    return 'toolbarButton ' + (this.props.callOn ? 'callEnd' : 'callStart');
  }
  get micropohoneStyle() {
    return 'toolbarButton ' + (this.props.microphoneOn ? 'microphoneOn' : 'microphoneOff');
  }

  showModal = () => {
    this.setState({ modalVisible: true });
  }

  handleClose = () => {
    this.setState({
      modalVisible: !this.state.modalVisible,
      // clean temparary selected devices
      selectedCamera: null,
      selectedMicrophone: null,
      selectedSpeaker: null,
    });
  }

  handleOk = () => {
    const { selectedCamera: tempCamera, selectedMicrophone: tempMic, selectedSpeaker: tempSpeaker } = this.state;
    const {
      selectedCamera, changeCamera,
      selectedMicrophone, changeMicrophone,
      selectedSpeaker, changeSpeaker,
    } = this.props;
    if (tempCamera && tempCamera.id !== selectedCamera) {
      changeCamera(tempCamera);
    }
    if (tempMic && tempMic.id !== selectedMicrophone) {
      changeMicrophone(tempMic);
    }
    if (tempSpeaker && tempSpeaker.id !== selectedSpeaker) {
      changeSpeaker(tempSpeaker);
    }
    this.handleClose();
  }

  selectDeviceTemp = <T extends ILocalDevice>(m: T, type: 'Camera' | 'Microphone' | 'Speaker') => {
    if (type === 'Camera') {
      this.setState({ selectedCamera: m as unknown as ILocalCamera });
    } else if (type === 'Microphone') {
      this.setState({ selectedMicrophone: m as unknown as ILocalMicrophone });
    } else if (type === 'Speaker') {
      this.setState({ selectedSpeaker: m as unknown as ILocalSpeaker });
    }
  }

  renderDevices = <T extends ILocalDevice>(type: 'Camera' | 'Microphone' | 'Speaker', devices: ILocalDevices<T>, selected?: string) => {
    // @ts-ignore
    const selectedDevice = this.state['selected' + type] || devices[selected] || null;

    const menu = (
      <Menu selectedKeys={[selectedDevice ? selectedDevice.id : null]}>
        {map(devices, (m: ILocalDevice, k: string) => (
          <Menu.Item key={k}>
            <div onClick={this.selectDeviceTemp.bind(this, m, type)}>{m.name}</div>
          </Menu.Item>
        ))}
      </Menu>
    );

    return (
      <Dropdown overlay={menu}>
        <Button style={{ marginLeft: 8 }}>
          {selectedDevice ? selectedDevice.name : ''} <Icon type="down" />
        </Button>
      </Dropdown>
    );
  }

  render() {
    const {
      callOn,
      toggleCamera,
      toggleCall,
      toggleMicrophone,
      cameras, selectedCamera,
      microphones, selectedMicrophone,
      speakers, selectedSpeaker,
      toggleChat, isMobile,
    } = this.props;

    return (
      <div id="toolbarCenter" className="toolbar">
        <button id="cameraButton" title="Camera Privacy" className={this.cameraStyle} onClick={toggleCamera} />
        <button id="joinLeaveButton" title="Join/Leave Conference" className={this.callStyle} onClick={toggleCall} />
        <button id="microphoneButton" title="Microphone Privacy" className={this.micropohoneStyle} onClick={toggleMicrophone} />
        {isMobile && callOn && <button id="microphoneButton" title="Chat" className="toolbarButton chat" onClick={toggleChat} />}
        <button id="more" title="More" className="toolbarButton more" onClick={this.showModal} />
        <Modal
          title="Settings"
          visible={this.state.modalVisible}
          onOk={this.handleOk}
          onCancel={this.handleClose}>
          <Form>
            <Form.Item label="Camera" {...formItemLayout}>
              {this.renderDevices('Camera', cameras, selectedCamera)}
            </Form.Item>
            <Form.Item label="Microphones" {...formItemLayout}>
              {this.renderDevices('Microphone', microphones, selectedMicrophone)}
            </Form.Item>
            <Form.Item label="Speaker" {...formItemLayout}>
              {this.renderDevices('Speaker', speakers, selectedSpeaker)}
            </Form.Item>
          </Form>
        </Modal>
      </div >
    );
  }
}
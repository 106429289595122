import { createAction } from '@reduxjs/toolkit';
import { ILoginSucc, ILoginFail, ILogoutSucc, ILogoutFail } from './types';

export const authActions = {
  checkLogin: createAction('CHECK_LOGIN'),
  login: createAction('LOGIN'),
  logout: createAction('LOGOUT'),
};

export const authEpicActions = {
  loginSucc: createAction<ILoginSucc, 'LOGIN_SUCC'>('LOGIN_SUCC'),
  loginFail: createAction<ILoginFail, 'LOGIN_FAIL'>('LOGIN_FAIL'),
  logoutSucc: createAction<ILogoutSucc, 'LOGOUT_SUCC'>('LOGOUT_SUCC'),
  logoutFail: createAction<ILogoutFail, 'LOGOUT_FAIL'>('LOGOUT_FAIL'),
};

interface IFirebaseAuthConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
}
export enum ServerType {
  development = 'development',
  stagingDev = 'stagingDev',
  staging = 'staging',
  qa = 'qa',
  production = 'production',
}
export interface Conf {
  type: ServerType;
  version: string;
  Host: string; // This is used for inContact redirect link. NOTE: (redirect url needs to be added to inContact website)
  ServerUrl: string;
  NotificationApi: string;
  NotificationApiKey: string;
  SenderEmail: string;
  FirebaseAuth: IFirebaseAuthConfig;
}

export class Config {
  private static _Version: string;
  private static _ServerType: ServerType;
  private static _ServerUrl: string;
  private static _NotificationApi: string;
  private static _NotificationApiKey: string;
  private static _SenderEmail: string;
  private static _FirebaseAuth: IFirebaseAuthConfig;

  public static initialize(conf: Conf) {
    Config._Version = conf.version;
    Config._ServerType = conf.type;
    Config._ServerUrl = conf.ServerUrl;
    Config._NotificationApi = conf.NotificationApi;
    Config._NotificationApiKey = conf.NotificationApiKey;
    Config._SenderEmail = conf.SenderEmail;
    Config._FirebaseAuth = conf.FirebaseAuth;
  }

  public static get Version() {
    return Config._Version;
  }

  public static get ServerType() {
    return Config._ServerType;
  }

  public static get Host() {
    return window.location.origin;
  }

  public static get ServerUrl() {
    return Config._ServerUrl;
  }

  public static get NotificationApi() {
    return Config._NotificationApi;
  }

  public static get NotificationApiKey() {
    return Config._NotificationApiKey;
  }

  public static get SenderEmail() {
    return Config._SenderEmail;
  }

  public static get IsProd() {
    return this.ServerType === ServerType.production;
  }

  public static get FirebaseAuth() {
    return Config._FirebaseAuth;
  }
}

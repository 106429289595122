import React, { FC, useEffect, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppointmentsList } from '../../Components/AppointmentsList';
import { PageContainer } from '../../Components/PageContainer';
import { RootState } from '../../Store';
import { appointmentActions } from '../../Store/Appointment/actions';
import { CenterContainer, Loading, Button } from 'primitive-comps';
import { Message } from '../../Components/Message';
import { useNoAuthRedirect } from '../../Hooks/UseNoAuthRedirect';
import './styles.css';
import { IProviderAppointment } from '../../Store/Appointment/types';
import { useGoToCallPage } from '../../Hooks/UseGoToCallPage';
import { Container } from '../../Components/Container';
import { CsvTimelineButton } from '../../Components/CsvTimelineButton';
import { getAllAppointmetsTimelines } from '../../Components/Util/timeline';
import { useRouter } from '../../Hooks';

export interface IAppointmentPageQueries {
  startTime?: string;
  endTime?: string;
}

export const Appointments: FC = () => {
  const { isLoggedIn, isAdmin } = useNoAuthRedirect();
  const pushToCall = useGoToCallPage();
  const dispatch = useDispatch();
  const { params: { providerId }, query, history: { replace } } = useRouter<{ providerId: string | undefined }, IAppointmentPageQueries>();
  const { loading, loadingMore, data, error, hasMore } = useSelector((state: RootState) => state.providerAppointments);
  const providerDisplayName = useSelector((state: RootState) => (state.auth.data && state.auth.data.user.displayName) || 'Provider');
  console.log(query);

  useEffect(() => {
    if (isAdmin) {
      if (providerId) {
        dispatch(appointmentActions.getProviderAppointments({
          providerId,
          startTime: query.startTime ? +query.startTime : undefined,
          endTime: query.endTime ? +query.endTime : undefined,
        }));
      }
      return;
    } else {
      if (isLoggedIn) {
        if (providerId) {
          replace('/appointments');
        } else {
          dispatch(appointmentActions.getProviderAppointments());
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, isAdmin, providerId]);

  const loadMore = useCallback(() => { dispatch(appointmentActions.getMoreProviderAppointments()); }, [dispatch]);

  const handleClick = useCallback((apt: IProviderAppointment) => {
    pushToCall({
      isMember: 'false',
      resourceId: apt.resourceId,
      token: apt.token,
      displayName: providerDisplayName,
      appointmentId: apt.appointmentId,
    });
  }, [providerDisplayName, pushToCall]);

  const timelines = useMemo(() => {
    if (data && data.appointments && data.appointments.length > 0) {
      return getAllAppointmetsTimelines(data.appointments, data.provider);
    }
    return null;
  }, [data]);

  const render = () => {
    if (isAdmin && !providerId) {
      return <Message>Error: No providerId provided!</Message>;
    }
    if (loading) {
      return (
        <CenterContainer>
          <Loading loading />
        </CenterContainer>
      );
    }
    return (
      <div className="appointments-page-container">
        {data && timelines && isAdmin &&
          <div className="appointments-button">
            <CsvTimelineButton name={data.provider.id} timelines={timelines} />
          </div>
        }
        <div style={{ width: '100%', height: '100%' }}>
          {typeof error === 'string' && <Message>{error}</Message>}
          {!error && <AppointmentsList appointments={(data && data.appointments) || []} onClick={handleClick} isAdmin={isAdmin} />}
        </div>
        {hasMore && <div className="appointments-button"><Button className="button" onClick={loadMore} loading={loadingMore} disabled={loadingMore}>Load More</Button></div>}
      </div>
    );
  };

  return (
    <Container contentFit>
      <PageContainer title="Appointments">
        {render()}
      </PageContainer>
    </Container>
  );
};
import { createReducer } from '@reduxjs/toolkit';
import { providersActions, providersEpicActions } from './actions';
import { IGetProvidersSucc } from './types';

export interface IProvidersState {
  loading: boolean;
  data?: IGetProvidersSucc;
  error?: string;
}
const initialState: IProvidersState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const providersReducer = createReducer(initialState, builder =>
  builder
    // GET
    .addCase(providersActions.getProviders, (state, action) => ({ loading: true, data: undefined, error: undefined, }))
    .addCase(providersEpicActions.getProvidersSucc, (state, action) => ({ loading: false, data: action.payload, error: undefined, }))
    .addCase(providersEpicActions.getProvidersFail, (state, action) => ({ loading: false, data: undefined, error: action.payload.error, }))
);

import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from '../../Store/Auth/actions';
import loginButtonImg from './loginButtonImg.png';
import './styles.css';

export const LoginButton: FC = () => {
  const dispatch = useDispatch();
  const login = useCallback(() => { dispatch(authActions.login()); }, [dispatch]);

  return <img className="login-button" onClick={login} src={loginButtonImg} alt="Sign In with Google" />;
};

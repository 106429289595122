export const TIME_FORMAT = 'hh:mm A';
export const TIME_WITH_SEC_FORMAT = 'hh:mm:ss A';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const STANDARD_DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_COMPELETE_FORAMT = 'dddd, MMMM Do YYYY';
export const DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm A';
export const DATE_TIME_WITH_SEC_FORMAT = 'MM/DD/YYYY hh:mm:ss A';
export const SERVER_DATE_FORMAT = 'YYYY-MM-DD';
export const SERVER_DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm A';
export const TELEMEDICINE_DATE_TIME_FORMAT = 'YYYY-MM-DD hh:mm A';
export const DATE_TIME_WITH_TIMEZONE_FORMAT = 'MM/DD/YYYY hh:mm A Z';
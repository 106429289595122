import React from 'react';
import { Route } from 'react-router-dom';
import { Home } from './Page/Home';
import { Appointments } from './Page/Appointments';
import { Admin } from './Page/Admin';
import { MemberPage } from './Page/Member';
import { Call } from './Page/Call';
import { TempRoom } from './Page/TempRoom';

export const Router = () => (
  <>
    <Route exact path="/" component={Home} />
    <Route exact path="/login-form" component={Home} />
    <Route exact path="/appointments" component={Appointments} />
    <Route exact path="/appointments/:providerId" component={Appointments} />
    <Route exact path="/admin" component={Admin} />
    <Route exact path="/member" component={MemberPage} />
    <Route exact path="/call" component={Call} />
    <Route exact path="/tempRoom" component={TempRoom} />
  </>
);
import React, { FC } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Router } from './Router';
import { Header } from './Components/Header';
import { CheckAuth } from './Components/CheckAuth';
import './App.css';

export const App: FC = () => {
  return (
    <CheckAuth>
      <div className="App">
        <BrowserRouter>
          <Header />
          <Router />
        </BrowserRouter>
      </div>
    </CheckAuth>
  );
};

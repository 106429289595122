import { createAction } from '@reduxjs/toolkit';
import { IGetProvidersSucc, IGetProvidersFail } from './types';

export const providersActions = {
  getProviders: createAction('GET_PROVIDERS'),
};

export const providersEpicActions = {
  getProvidersSucc: createAction<IGetProvidersSucc, 'GET_PROVIDERS_SUCC'>('GET_PROVIDERS_SUCC'),
  getProvidersFail: createAction<IGetProvidersFail, 'GET_PROVIDERS_FAIL'>('GET_PROVIDERS_FAIL'),
};

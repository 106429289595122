import React, { FC } from 'react';

const style = {
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
};
export const Center: FC = ({ children }) => (
  <div style={style}>
    {children}
  </div>
);
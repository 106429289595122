import React, { useEffect, useState, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { authActions } from '../../Store/Auth/actions';
import { RootState } from '../../Store';
import { Loading } from '../Loading';
import { usePrevious } from '../../Hooks';

/**
 * Check to see if the user is signed in
 * renders children once check is done 
 * whether the user is signed in or not
 */
export const CheckAuth: FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { dispatch(authActions.checkLogin()); }, []);
  const auth = useSelector((state: RootState) => state.auth);
  const prevLoading = usePrevious(auth.loading);
  useEffect(() => { if (loading && prevLoading && !auth.loading) setLoading(false); }, [prevLoading, auth.loading, loading]);

  if (loading) {
    return <Loading center />;
  }

  return <>{children}</>;
};

import { createAction } from '@reduxjs/toolkit';
import { IGetGenerateRoomSucc, IGetGenerateRoomFail } from './types';

export const generateRoomActions = {
  get: createAction('GET_GENERATE_ROOM'),
};

export const generateRoomEpicActions = {
  getSucc: createAction<IGetGenerateRoomSucc, 'GET_GENERATE_ROOM_SUCC'>('GET_GENERATE_ROOM_SUCC'),
  getFail: createAction<IGetGenerateRoomFail, 'GET_GENERATE_ROOM_FAIL'>('GET_GENERATE_ROOM_FAIL'),
};

import { filter, switchMap, map, catchError, timeout, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ajax } from '../Epics/helpers';
import { Epic } from '../Epics/EpicType';
import { appointmentActions, appointmentEpicActions } from './actions';
import { Config } from '../../config';
import { IGetProviderAppointmetnsSucc } from './types';

const TIMEOUT = 15000;

export const getProviderAppointmentsEpic: Epic = (action$, state$) => action$.pipe(
  filter(appointmentActions.getProviderAppointments.match),
  withLatestFrom(state$),
  switchMap(([_, state]) =>
    ajax.get<IGetProviderAppointmetnsSucc>(
      Config.ServerUrl + '/getProviderAppointments',
      { ...state.providerAppointments.payload },
      { authorization: 'Bearer ' + state.auth.data!.token }
    ).pipe(
      timeout(TIMEOUT),
      map((res) => appointmentEpicActions.getProviderAppointmentsSucc(res.response)),
      catchError((error) => of(appointmentEpicActions.getProviderAppointmentsFail({ error: (error.response ? error.response.error : error.message) || 'Something went wrong!' })))
    )
  )
);

export const getMoreProviderAppointmentsEpic: Epic = (action$, state$) => action$.pipe(
  filter(appointmentActions.getMoreProviderAppointments.match),
  withLatestFrom(state$),
  switchMap(([_, state]) =>
    ajax.get<IGetProviderAppointmetnsSucc>(
      Config.ServerUrl + '/getProviderAppointments',
      { ...state.providerAppointments.payload },
      { authorization: 'Bearer ' + state.auth.data!.token }
    ).pipe(
      timeout(TIMEOUT),
      map((res) => appointmentEpicActions.getMoreProviderAppointmentsSucc(res.response)),
      catchError((error) => of(appointmentEpicActions.getMoreProviderAppointmentsFail({ error: (error.response ? error.response.error : error.message) || 'Something went wrong!' })))
    )
  )
);

export const agreement = `Telemedicine services involve the use of interactive videoconferencing equipment devices or electronic communication to enable health care providers at different locations to deliver health care services, including but not limited to evaluations, consultations, diagnoses, treatments, and follow-up services, to patients.

1.	I understand that I will not be physically in the same room as my health care provider, but the standard of care for in-person visits applies to telemedicine visits.

2.	I understand that in order to provide appropriate diagnosis/treatment, video, audio and/or photo recordings and a physical examination of me may be taken during the telemedicine visit. The details of my medical history, examinations, x-rays, and test may be discussed with other health care providers.

3.	I understand that some parts of the exam involving physical tests may be conducted by individuals at my location, or at a testing facility, at the direction of the consulting healthcare provider.

4.	I understand that I may be required to verify my identity and location and to transmit the necessary clinical data or images to the remote health care provider. I understand that I also have a right to request the health care provider’s name and credentials including but not limited to the provider’s license, title, specialty, and board certifications.

5.	I understand that there are potential risks to using technology, including service interruptions, interception, and technical difficulties. If it is determined that the videoconferencing equipment and/or connection is not adequate, I understand that my health care provider or I may discontinue the telemedicine visit and make other arrangements to continue the visit. 

6.	I understand that I have the right to refuse to participate or decide to stop participating in a telemedicine visit and request a face-to-face service. I understand that my refusal will be documented in my medical record. I also understand that my refusal will not affect my right to future care or treatment. I may revoke my refusal at any time.  

7.	I understand that the laws that protect privacy, security and the confidentiality of health care information apply to telemedicine services, but these laws have been relaxed by the government to promote social distancing and to help protect me, other residents, employees and clinical staff from exposure to the COVID-19 virus during this public health emergency period. While the telemedicine services will be provided through non-public facing platforms, the images, video communication, emails and text messages that may be part of the telemedicine visit may not be encrypted, or secured from access by outside parties.  

8.	I understand that my health care information may be shared with other individuals for scheduling and billing purposes.

9.	I understand that my insurance carrier will have access to my medical records for quality review/audit. 

10.	I understand that this document will become a part of my medical record and that I will have access to my medical records as it relates to the telemedicine visit. 

11.	I understand that any dispute arising from the telemedicine visit will be resolved in the State of Delaware, and that Delaware law shall apply to all disputes.




I attest that I: 

a.	have personally read this form (or had it explained to me) and fully understand and agree to its contents; 

b.	have had my questions answered to my satisfaction, and the risks, benefits, and alternatives to telemedicine visits shared with me in a language I understand; and 

c.	give my consent to participate in telemedicine visits. 

`;

export const acknowledgement = "I acknowledge I have read and understand the policies above. I accept the rights and responsibilities outlined within them.";
import React, { FC } from 'react';
import './styles.css';

interface IProps {
  center?: boolean;
  withoutHeader?: boolean;
  contentFit?: boolean;
}
export const Container: FC<IProps> = ({ children, center, withoutHeader, contentFit }) => {
  return (
    <div className={'container' + (center ? ' center' : '') + (withoutHeader ? ' without-header' : '')}>
      <div className={'content' + (contentFit ? ' fit' : '')}>
        {children}
      </div>
    </div>
  );
};
import { combineEpics } from 'redux-observable';
import { errorEpic } from '../Errors/epics';
import * as auth from '../Auth/epics';
import * as providerAppointments from '../Appointment/epic';
import * as Providers from '../Providers/epic';
import * as memberAppointment from '../MemberAppointment/epic';
import * as leaveOrJoin from '../JoinLeaveAppointment/epic';
import * as generateRoom from '../GenerateRoom/epic';

export const rootEpic = combineEpics(
  errorEpic,
  auth.checkLoggedInEpic,
  auth.loginEpic,
  auth.logoutEpic,
  providerAppointments.getProviderAppointmentsEpic,
  providerAppointments.getMoreProviderAppointmentsEpic,
  memberAppointment.getMemberAppointmentEpic,
  leaveOrJoin.memberJoinOrLeaveEpic,
  leaveOrJoin.providerJoinOrLeaveEpic,
  Providers.getProvidersEpic,
  generateRoom.generateRoomEpic,
);
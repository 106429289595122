import React, { Component } from 'react';
import { Center } from '../Center';
import { Loading } from '../Loading';
import { getVidyoClient, IVidyoConnector, createVidyoConnector, ILocalDevices, ILocalCamera, ILocalMicrophone, ILocalSpeaker, ILocalDevice } from '../../Vidyo';
import { isEmpty } from 'lodash';
import { VidyoToolbar } from '../VidyoToolbar';
import { VidyoChat } from '../VidyoChat';
import { Alert } from 'primitive-comps';
import { Container } from '../Container';
import './styles.css';

const SUCCESS_CSS = 'background-color: #fff; font-size: 1.5em; color: green;';
const FAIL_CSS = 'background-color: #fff; font-size: 1.5em; color: red;';
// const WARNING_CSS = 'background-color: #fff; font-size: 1.5em; color: orange';
const VIDYO_CONNECTOR_IS_NULL = 'Vidyo connector is null!';

interface IProps {
  displayName: string;
  token: string;
  resourceId: string;
  onClose: () => void;
  onJoin: () => void;
  onLeave: () => void;
}
interface IState {
  cameras: ILocalDevices<ILocalCamera>;
  microphones: ILocalDevices<ILocalMicrophone>;
  speakers: ILocalDevices<ILocalSpeaker>;
  selectedCamera?: string;
  selectedMicrophone?: string;
  selectedSpeaker?: string;
  microphonePrivacy: boolean;
  cameraPrivacy: boolean;
  connection: boolean;
  hasVidyoConnector: boolean;
  participantsCount: number;
  error?: string;
  vidyoError?: string;
  isMobile: boolean;
  chatShown: boolean;
}
export class Vidyo extends Component<IProps, IState> {
  vidyoConnector?: IVidyoConnector;
  selectCamera: (device: ILocalCamera) => Promise<boolean>;
  selectMicrophone: (device: ILocalMicrophone) => Promise<boolean>;
  selectSpeaker: (device: ILocalSpeaker) => Promise<boolean>;

  constructor(props: IProps) {
    super(props);
    this.selectCamera = this.selectLocalDevice('Camera');
    this.selectMicrophone = this.selectLocalDevice('Microphone');
    this.selectSpeaker = this.selectLocalDevice('Speaker');
    this.state = {
      cameras: {},
      microphones: {},
      speakers: {},
      selectedCamera: undefined,
      selectedMicrophone: undefined,
      selectedSpeaker: undefined,
      microphonePrivacy: false,
      cameraPrivacy: false,
      connection: false,
      hasVidyoConnector: false,
      participantsCount: 1,
      error: undefined,
      vidyoError: undefined,
      isMobile: window.innerWidth < 769,
      chatShown: false,
    };
    this.init();
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentWillUnmount() {
    if (!this.vidyoConnector) { return; }
    if (this.state.connection) {
      this.disconnect(this.vidyoConnector);
    }
    this.unregisterDeviceListeners(this.vidyoConnector);
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (window.innerWidth >= 769 && this.state.isMobile) {
      this.setState({ isMobile: false });
    } else if (window.innerWidth < 769 && !this.state.isMobile) {
      this.setState({ isMobile: true });
    }
  }

  async init() {
    try {
      const vc = await getVidyoClient();
      this.vidyoConnector = await createVidyoConnector(
        vc, {
        viewId: 'renderer',                            // Div ID where the composited video will be rendered, see VidyoConnector.html
        viewStyle: 'VIDYO_CONNECTORVIEWSTYLE_Default', // Visual style of the composited renderer
        remoteParticipants: 2,                         // Maximum number of participants
        logFileFilter: '',                             // 'warning all@VidyoConnector info@VidyoClient',
        logFileName: '',
        userData: ''
      });
      this.setState({ hasVidyoConnector: true });
      const status = await this.connect(this.vidyoConnector);
      if (status) {
        this.registerDeviceListeners(this.vidyoConnector);
      } else {

      }
    } catch (err) {
      this.setState({ error: err.message });
    }
  }

  connect = (vidyoConnector: IVidyoConnector) => {
    return vidyoConnector.Connect({
      host: 'prod.vidyo.io',
      token: this.props.token,
      displayName: this.props.displayName,
      resourceId: this.props.resourceId,
      onSuccess: () => {
        console.log('join');
        this.props.onJoin();
        this.setState({ connection: true, error: undefined, vidyoError: undefined });
      },
      onFailure: (reason) => {
        this.setState({ connection: false, error: 'Call Failed!', vidyoError: reason });
      },
      onDisconnected: (reason) => {
        this.props.onLeave();
        console.log('leave');
        this.setState({ connection: false, error: undefined, vidyoError: undefined });
      }
    });
  }

  disconnect = (vidyoConnector: IVidyoConnector) => {
    return vidyoConnector.Disconnect();
  }

  /* Camera event listener */
  registerCameraEventListener = async (vidyoConnector: IVidyoConnector) => {
    try {
      await vidyoConnector.RegisterLocalCameraEventListener({
        onAdded: (localCamera) => {  /* New camera is available */
          const { cameras } = this.state;
          let { selectedCamera } = this.state;
          if (isEmpty(cameras)) {
            vidyoConnector.SelectLocalCamera({ localCamera });
            selectedCamera = localCamera.id;
          }
          cameras[localCamera.id] = localCamera;
          this.setState({ cameras, selectedCamera });
        },
        onRemoved: (localCamera) => { /* Existing camera became unavailable */
          const { cameras } = this.state;
          delete cameras[localCamera.id];
          this.setState({ cameras });
        },
        onSelected: (localCamera) => { /* Camera was selected by user or automatically */
          this.setState({ selectedCamera: localCamera.id });
        },
        onStateUpdated: (localCamera, state) => { /* Camera state was updated */ }
      });
      console.log('RegisterLocalCameraEventListener Success');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /* Microphone event listener */
  registerMicrophoneEventListener = async (vidyoConnector: IVidyoConnector) => {
    try {
      await vidyoConnector.RegisterLocalMicrophoneEventListener({
        onAdded: (localMicrophone) => { /* New microphone is available */
          const { microphones } = this.state;
          let { selectedMicrophone } = this.state;
          if (isEmpty(microphones)) {
            vidyoConnector.SelectLocalMicrophone({ localMicrophone });
            selectedMicrophone = localMicrophone.id;
          }
          microphones[localMicrophone.id] = localMicrophone;
          this.setState({ microphones, selectedMicrophone });
        },
        onRemoved: (localMicrophone) => { /* Existing microphone became unavailable */
          const { microphones } = this.state;
          delete microphones[localMicrophone.id];
          this.setState({ microphones });
        },
        onSelected: (localMicrophone) => { /* Microphone was selected by user or automatically */
          this.setState({ selectedMicrophone: localMicrophone.id });
        },
        onStateUpdated: (localMicrophone, state) => { /* Microphone state was updated */ }
      });
      console.log('RegisterLocalMicrophoneEventListener Success');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  /* Speaker event listener */
  registerSpeakerEventListener = async (vidyoConnector: IVidyoConnector) => {
    try {
      await vidyoConnector.RegisterLocalSpeakerEventListener({
        onAdded: (localSpeaker) => { /* New speaker is available */
          const { speakers } = this.state;
          let { selectedSpeaker } = this.state;
          if (isEmpty(speakers)) {
            vidyoConnector.SelectLocalSpeaker({ localSpeaker });
            selectedSpeaker = localSpeaker.id;
          }
          speakers[localSpeaker.id] = localSpeaker;
          this.setState({ speakers, selectedSpeaker });
        },
        onRemoved: (localSpeaker) => { /* Existing speaker became unavailable */
          const { speakers } = this.state;
          delete speakers[localSpeaker.id];
          this.setState({ speakers });
        },
        onSelected: (localSpeaker) => { /* Speaker was selected by user or automatically */
          this.setState({ selectedSpeaker: localSpeaker.id });
        },
        onStateUpdated: (localSpeaker, state) => { /* Speaker state was updated */ }
      });
      console.log('RegisterLocalSpeakerEventListener Success');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  registerParticipantEventListener = async (vidyoConnector: IVidyoConnector) => {
    try {
      await vidyoConnector.RegisterParticipantEventListener({
        onJoined: (participant) => {
          console.log('%cParticipant joined! %s', SUCCESS_CSS, participant.name);
          // this.setState({ participantsCount: this.state.participantsCount + 1 });
        },
        onLeft: (participant) => {
          console.log('%cParticipant left! %s', FAIL_CSS, participant.name);
          this.setState({ participantsCount: this.state.participantsCount - 1 });
        },
        onDynamicChanged: (participants) => {
          /* Ordered array of participants according to rank */
          // this state change is in case someone joined after and doesn't have the correct participant count
          if (this.state.participantsCount !== participants.length) {
            this.setState({ participantsCount: participants.length });
          }
        },
        onLoudestChanged: (participant, audioOnly) => {
          /* Current loudest speaker */
        }
      });
      console.log('RegisterParticipantEventListener Success');
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  }

  selectLocalDevice = <T extends ILocalDevice>(type: 'Camera' | 'Microphone' | 'Speaker') => (device: T): Promise<boolean> => {
    if (!this.vidyoConnector) { throw new Error(VIDYO_CONNECTOR_IS_NULL); }
    switch (type) {
      case 'Camera': return this.vidyoConnector.SelectLocalCamera({ localCamera: device as unknown as ILocalCamera });
      case 'Microphone': return this.vidyoConnector.SelectLocalMicrophone({ localMicrophone: device as unknown as ILocalMicrophone });
      case 'Speaker': return this.vidyoConnector.SelectLocalSpeaker({ localSpeaker: device as unknown as ILocalSpeaker });
      default: throw new Error('Device type is wrong!');
    }
  }

  registerDeviceListeners = (vidyoConnector: IVidyoConnector) => {
    return Promise.all([
      this.registerCameraEventListener(vidyoConnector),
      this.registerMicrophoneEventListener(vidyoConnector),
      this.registerSpeakerEventListener(vidyoConnector),
      this.registerParticipantEventListener(vidyoConnector),
    ]);
  }

  unregisterDeviceListeners = (vidyoConnector: IVidyoConnector) => {
    return Promise.all([
      vidyoConnector.UnregisterLocalCameraEventListener(),
      vidyoConnector.UnregisterLocalMicrophoneEventListener(),
      vidyoConnector.UnregisterParticipantEventListener(),
      vidyoConnector.UnregisterLocalSpeakerEventListener(),
    ]);
  }

  toggelMicrophonePrivacy = async () => {
    const { microphonePrivacy } = this.state;
    try {
      if (!this.vidyoConnector) { throw new Error(VIDYO_CONNECTOR_IS_NULL); }
      const status = await this.vidyoConnector.SetMicrophonePrivacy({ privacy: !microphonePrivacy });
      if (status) {
        this.setState({ microphonePrivacy: !microphonePrivacy });
        return true;
      } else {
        console.error(`Couldn't ${microphonePrivacy ? 'unmute' : 'mute'}`);
        return false;
      }
    } catch (error) {
      console.error(`Couldn't ${microphonePrivacy ? 'unmute' : 'mute'}`, error);
      return false;
    }
  }

  toggleCameraPrivacy = async () => {
    const { cameraPrivacy } = this.state;
    try {
      if (!this.vidyoConnector) { throw new Error(VIDYO_CONNECTOR_IS_NULL); }
      const status = await this.vidyoConnector.SetCameraPrivacy({ privacy: !cameraPrivacy });
      if (status) {
        this.setState({ cameraPrivacy: !cameraPrivacy });
        return true;
      } else {
        console.error(`Couldn't ${cameraPrivacy ? 'mute camera' : 'mute camera'}`);
        return false;
      }
    } catch (error) {
      console.error(`Couldn't ${cameraPrivacy ? 'unmute camera' : 'mute camera'}`, error);
      return false;
    }
  }

  toggleConnection = async () => {
    const { connection } = this.state;
    try {
      if (!this.vidyoConnector) { throw new Error(VIDYO_CONNECTOR_IS_NULL); }
      const status = !connection ? await this.connect(this.vidyoConnector) : await this.disconnect(this.vidyoConnector);
      if (status) {
        console.log(connection ? 'disconnected' : 'connected');
        return true;
      } else {
        console.error(`Couldn't ${connection ? 'disconnect' : 'connect'}`);
        return false;
      }
    } catch (error) {
      console.error(`Couldn't ${connection ? 'disconnect' : 'connect'}`, error);
      return false;
    }
  }

  renderErrorAlert = (error: string) => {
    let message = '';
    switch (error) {
      case 'VIDYO_CONNECTORFAILREASON_InvalidToken':
        message = 'Invalid Token! The token might be expired!';
        break;
      default: message = 'Something went wrong!! (' + error + ')';
    }
    return <Alert
      style={{ width: '90%', margin: '30px auto' }}
      message="Error!!!"
      description={message}
      type="error"
      closeText="Go Back"
      onClose={this.props.onClose}
      showIcon
    />;
  }

  toggleChat = () => {
    this.setState({ chatShown: !this.state.chatShown });
  }

  render() {
    const { displayName } = this.props;
    const { hasVidyoConnector, vidyoError, connection, isMobile, chatShown } = this.state;

    if (vidyoError) {
      return <Container contentFit><Center>{this.renderErrorAlert(vidyoError)}</Center></Container>;
    }
    return (
      <Container contentFit>
        <Center>
          {!hasVidyoConnector && <Loading />}
          <div className={'camera-and-toolbar-container' + (!connection ? ' not-connected' : '')}>
            <div id="renderer" className="camera" hidden={!hasVidyoConnector} />
            {hasVidyoConnector && <VidyoToolbar
              cameraOn={!this.state.cameraPrivacy}
              callOn={this.state.connection}
              microphoneOn={!this.state.microphonePrivacy}
              toggleCamera={this.toggleCameraPrivacy}
              toggleCall={this.toggleConnection}
              toggleMicrophone={this.toggelMicrophonePrivacy}
              cameras={this.state.cameras}
              microphones={this.state.microphones}
              speakers={this.state.speakers}
              changeCamera={this.selectCamera}
              changeMicrophone={this.selectMicrophone}
              changeSpeaker={this.selectSpeaker}
              selectedCamera={this.state.selectedCamera}
              selectedMicrophone={this.state.selectedMicrophone}
              selectedSpeaker={this.state.selectedSpeaker}
              toggleChat={this.toggleChat}
              isMobile={isMobile}
            />}
          </div>
          {hasVidyoConnector && this.vidyoConnector &&
            <div className="chat-container" hidden={!connection || (isMobile && !chatShown)}>
              <VidyoChat vidyoConnector={this.vidyoConnector} userName={displayName} disabled={!connection} />
            </div>
          }
        </Center>
      </Container>
    );
  }
}


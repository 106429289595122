import React, { FC, useState } from 'react';
import { Vidyo } from '../../Components/Vidyo';
import { Input, Button } from 'primitive-comps';
import { useRouter } from '../../Hooks';
import { Message } from '../../Components/Message';

interface IQueryString {
  token: string;
  resourceId: string;
}
export const TempRoom: FC = () => {
  const [name, setName] = useState('');
  const [showRoom, setShowRoom] = useState(false);
  const { query } = useRouter<{}, IQueryString>();

  const handleGoToRoom = () => {
    if (name) {
      setShowRoom(true);
    }
  }

  if (!query.token || !query.resourceId) {
    return <Message>Invalid Link!</Message>
  }
  if (!showRoom) {
    return (
      <div>
        <Input value={name} onChange={(evt) => setName(evt.target.value)} placeholder="Name" />
        <Button className="button" onClick={handleGoToRoom}>Go To Room</Button>
      </div>
    );
  }
  return (
    <Vidyo
      displayName={name}
      token={query.token}
      resourceId={query.resourceId}
      onClose={() => { }}
      onJoin={() => { }}
      onLeave={() => { }}
    />
  );
};
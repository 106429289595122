import React, { FC } from 'react';
import { Button } from 'primitive-comps';
import { useDispatch, useSelector } from 'react-redux';
import { generateRoomActions } from '../../Store/GenerateRoom/actions';
import { RootState } from '../../Store';

export const GenerateRoomButton: FC = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state: RootState) => state.generateRoom.loading);

  const handleClick = () => {
    dispatch(generateRoomActions.get());
  };

  return <Button className="button" onClick={handleClick} disabled={isLoading} loading={isLoading}>Generate Room</Button>
};
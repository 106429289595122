// tslint:disable:no-any
import { ajax as rxajax, AjaxResponse as RXAjaxResponse, AjaxError as RXAjaxError } from 'rxjs/ajax';
import { of, Observable, Subject, throwError } from 'rxjs';
import { reduce } from 'lodash';
import { catchError } from 'rxjs/operators';

export const jsonToQuery = (json: { [key: string]: string | number | undefined }) => reduce(
  json,
  (result, value, key) => {
    if (value === undefined) {
      return result;
    }
    const divider = result === '' ? '' : '&';
    return result + `${divider}${key}=${value}`;
  },
  ''
);

const _errorStatusses = new Subject<number>();
export const errorStatussesObs = _errorStatusses.asObservable();

export const ajax = {
  get: <T extends any>(url: string, query: { [key: string]: string | number | undefined } = {}, headers?: { [key: string]: string | number }) => {
    const queryString = jsonToQuery(query);
    return rxajax.get(
      `${url}${queryString ? '?' + queryString : ''}`,
      headers
    ).pipe(
      catchError(errStatusProcessor),
    ) as Observable<AjaxResponse<T>>;
  },
  post: <T extends any>(url: string, body?: { [key: string]: string | number } | any, headers?: { [key: string]: string | number }) => {
    return rxajax.post(
      url,
      body,
      {
        'Content-Type': 'application/json',
        ...headers
      }
    ).pipe(
      catchError(errStatusProcessor)
    ) as Observable<AjaxResponse<T>>;
  },
  postWithCredentials: <T extends any>(url: string, body?: { [key: string]: string | number }, headers?: { [key: string]: string | number }) => {
    return rxajax({
      url,
      body,
      headers,
      method: 'POST',
      crossDomain: true,
      withCredentials: true,
    }).pipe(
      catchError(errStatusProcessor)
    ) as Observable<AjaxResponse<T>>;
  },
  put: <T extends any>(url: string, body?: { [key: string]: string | number } | any, headers?: { [key: string]: string | number }) => {
    return rxajax.put(
      url,
      body,
      {
        'Content-Type': 'application/json',
        ...headers
      }
    ).pipe(
      catchError(errStatusProcessor)
    ) as Observable<AjaxResponse<T>>;
  },
  delete: <T extends any>(url: string, headers: { [key: string]: string | number } = {}) => {
    return rxajax.delete(
      url,
      {
        'Content-Type': 'application/json',
        ...headers
      }
    ).pipe(
      catchError(errStatusProcessor)
    ) as Observable<AjaxResponse<T>>;
  }
};

export const createResponseAction = (type: string) => (res: any) => ({ type, response: res.response || res }); // tslint:disable-line
export const createErrorAction = (type: string) => (err: any) => of({ type, error: (err.response && err.response.error) || err.message }); // tslint:disable-line

export interface AjaxError<T extends { [key: string]: any } = any> extends RXAjaxError {
  response: T;
}
export interface AjaxResponse<T extends { [key: string]: any } = any> extends RXAjaxResponse {
  response: T;
}

function errStatusProcessor(response: RXAjaxResponse) {
  _errorStatusses.next(response.status);
  return throwError(response);
}

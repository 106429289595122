import React, { FC } from 'react';
import { Button } from 'antd';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store';
import { LoginButton } from '../LoginButton';
import { Loading } from 'primitive-comps';

export const LoginForm: FC = () => {
  const isLoggedIn = useSelector((state: RootState) => !!state.auth.data);
  const isAdmin = useSelector((state: RootState) => state.auth.data && state.auth.data.user.isAdmin);
  const isLoading = useSelector((state: RootState) => !!state.auth.loading);

  function render() {
    if (isLoading) {
      return <Loading loading />;
    } else if (isLoggedIn) {
      if (isAdmin) {
        return <Button><Link to="/admin">Go To Admins Page</Link></Button>;
      }
      return <Button><Link to="/appointments">Go To Appointments</Link></Button>;
    }
    return <LoginButton />;
  }

  return (
    <div className="login-form">
      {render()}
    </div>
  );
};

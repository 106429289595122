import { createAction, ActionsUnion } from '../actionsHelper';
import { SET_ERROR, CLEAR_ERROR, SUBSCRIBE_ERRORS, UNSUBSCRIBE_ERRORS } from './types';

export const errorActions = {
  subscribe: () => createAction(SUBSCRIBE_ERRORS),
  unsubscribe: () => createAction(UNSUBSCRIBE_ERRORS),
};

export const errorEpicActions = {
  set: (status: number) => createAction(SET_ERROR, status),
  clear: () => createAction(CLEAR_ERROR)
};

export type ErrorActionsType = ActionsUnion<typeof errorActions>;
export type ErrorEpicActionsType = ActionsUnion<typeof errorEpicActions>;
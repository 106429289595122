import React, { FC, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { authActions } from '../../Store/Auth/actions';

interface IProps {
  className?: string;
}
export const LogoutButton: FC<IProps> = ({ children, className }) => {
  const dispatch = useDispatch();
  const signOut = useCallback(() => { dispatch(authActions.logout()); }, [dispatch]);

  return <a href="/#" className={className} onClick={signOut}>{children}</a>;
}; 
import { filter, switchMap, map, catchError, timeout, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { ajax } from '../Epics/helpers';
import { Epic } from '../Epics/EpicType';
import { providersEpicActions, providersActions } from './actions';
import { Config } from '../../config';
import { IGetProvidersSucc } from './types';

const TIMEOUT = 15000;

export const getProvidersEpic: Epic = (action$, state$) => action$.pipe(
  filter(providersActions.getProviders.match),
  withLatestFrom(state$),
  switchMap(([_, state]) =>
    ajax.get<IGetProvidersSucc>(
      Config.ServerUrl + '/getProviders',
      {},
      { authorization: 'Bearer ' + state.auth.data!.token }
    ).pipe(
      timeout(TIMEOUT),
      map((res) => providersEpicActions.getProvidersSucc(res.response)),
      catchError((error) => of(providersEpicActions.getProvidersFail({ error: (error.response ? error.response.error : error.message) || 'Something went wrong!' })))
    )
  )
);

import { filter, switchMap, map, catchError } from 'rxjs/operators';
import { from, of } from 'rxjs';
import { authActions, authEpicActions } from './actions';
import { Epic } from '../Epics/EpicType';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import { ILoginSucc } from './types';
import { ajax } from '../Epics/helpers';
import { Config } from '../../config';

// Sign In
const signIn = async () => {
  await firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({
    'hd': 'medxm1.com'
  });
  provider.addScope('profile');
  return await firebase.auth().signInWithPopup(provider);
};

const getUserAndToken = (): Promise<ILoginSucc> => {
  return new Promise((resolve, reject) => {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        firebase.auth().currentUser!.getIdToken(/* forceRefresh */ true).then((token) => {
          return resolve({
            token,
            user: {
              refreshToken: user.refreshToken,
              uid: user.uid,
              displayName: user.displayName,
              photoURL: user.photoURL,
              email: user.email,
              emailVerified: user.emailVerified,
              phoneNumber: user.phoneNumber,
              isAnonymous: user.isAnonymous,
              isAdmin: false,
            }
          });
        });
      } else {
        return reject('Something went wrong! User is null!');
      }
    });
  });
};

// Sign Out
const signOut = async () => await firebase.auth().signOut();

export const loginEpic: Epic = (action$, state$) => action$.pipe(
  filter(authActions.login.match),
  switchMap(() => {
    return from(firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL));
  }),
  switchMap(() => {
    return from(signIn());
  }),
  switchMap(() => {
    return from(getUserAndToken());
  }),
  switchMap((info) => {
    if (info) {
      return ajax.get<{ isAdmin: boolean }>(Config.ServerUrl + '/isAdmin', {}, { authorization: 'Bearer ' + info.token }).pipe(
        map(({ response }) => ({ token: info.token, user: { ...info.user, isAdmin: response.isAdmin } })),
        catchError(() => of(info)),
      );
    }
    return of(info);
  }),
  map(authEpicActions.loginSucc),
  catchError((error) => of(authEpicActions.loginFail({ error })))
);

export const checkLoggedInEpic: Epic = (action$, state$) => action$.pipe(
  filter(authActions.checkLogin.match),
  switchMap(() => {
    return from(getUserAndToken());
  }),
  switchMap((info) => {
    if (info) {
      return ajax.get<{ isAdmin: boolean }>(Config.ServerUrl + '/isAdmin', {}, { authorization: 'Bearer ' + info.token }).pipe(
        map(({ response }) => ({ token: info.token, user: { ...info.user, isAdmin: response.isAdmin } })),
        catchError(() => of(info)),
      );
    }
    return of(info);
  }),
  map(authEpicActions.loginSucc),
  catchError((error) => of(authEpicActions.loginFail({ error })))
);

export const logoutEpic: Epic = (action$, state$) => action$.pipe(
  filter(authActions.logout.match),
  switchMap(() => {
    return from(signOut());
  }),
  map(() => authEpicActions.logoutSucc({})),
  catchError((error) => of(authEpicActions.logoutFail({ error })))
);


import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store';
import { Link } from 'react-router-dom';
import { Message } from '../Message';
import { Config } from '../../config';
import './styles.css';

export const TempRoomLink: FC = () => {
  const { data, error } = useSelector((state: RootState) => state.generateRoom);
  if (error)
    return <Message>{error}</Message>;
  if (!data)
    return null;

  const link = `/tempRoom?token=${data.token}&resourceId=${data.resourceId}`;
  return (
    <div className="temp-room-link-container">
      <Link to={link}>{Config.Host + link}</Link>;
    </div>
  );
};
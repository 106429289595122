import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../Store';

export const UserName: FC = () => {
  const data = useSelector((state: RootState) => state.auth.data);
  const name = data ? data.user.displayName : '';

  return <span>{name}</span>;
};

import { applyMiddleware, compose, createStore } from 'redux';
import { combineReducers } from '@reduxjs/toolkit';
import { createEpicMiddleware } from 'redux-observable';
import { rootEpic } from './Epics';
import { authReducer } from './Auth/reducer';
import { providerAppointmentsReducer } from './Appointment/reducer';
import { memberAppointmentReducer } from './MemberAppointment/reducer';
import { memberJoinOrLeaveReducer, providerJoinOrLeaveReducer } from './JoinLeaveAppointment/reducer';
import { providersReducer } from './Providers/reducer';
import { generateRoomReducer } from './GenerateRoom/reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  providerAppointments: providerAppointmentsReducer,
  memberAppointment: memberAppointmentReducer,
  memberJoinOrLeave: memberJoinOrLeaveReducer,
  providerJoinOrLeave: providerJoinOrLeaveReducer,
  providers: providersReducer,
  generateRoom: generateRoomReducer
});

export type RootState = ReturnType<typeof rootReducer>;

const epicMiddleware = createEpicMiddleware();

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION__: () => undefined;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__: (arg: any) => undefined; // tslint:disable-line
  }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export function configureStore(initialState?: RootState) {
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(epicMiddleware))
  );

  // @ts-ignore
  epicMiddleware.run(rootEpic);

  return store;
}

import React, { FC, useState, useCallback, useEffect, useRef } from 'react';
import { Input, Button, Alert, Checkbox, CheckboxChangeEvent } from 'primitive-comps';
import { useRouter } from '../../Hooks';
import { useGoToCallPage } from '../../Hooks/UseGoToCallPage';
import { useDispatch, useSelector } from 'react-redux';
import { memberAppointmentActions } from '../../Store/MemberAppointment/actions';
import { Message } from '../../Components/Message';
import { Container } from '../../Components/Container';
import { RootState } from '../../Store';
import './styles.css';
import logo from '../../Assets/logo-green.png';
import { agreement, acknowledgement } from './Agreement'

export const MemberPage: FC = () => {
  const dispatch = useDispatch();
  const { query: { id: appointmentId } } = useRouter<{}, { id: string }>();
  const [email, setEmail] = useState('');
  const memberAppointment = useSelector((state: RootState) => state.memberAppointment);
  const inputRef = useRef<Input>(null);
  const pushToCall = useGoToCallPage();


  const [hasAgreed, setHasAgreed] = useState(false);

  useEffect(() => {
    if (inputRef && inputRef.current)
      inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (memberAppointment.data) {
      pushToCall({
        isMember: 'true',
        memberId: memberAppointment.data.memberId,
        resourceId: memberAppointment.data.resourceId,
        token: memberAppointment.data.token,
        displayName: memberAppointment.data.displayName,
        appointmentId: memberAppointment.data.appointmentId,
      });
    }
  }, [memberAppointment.data, pushToCall]);

  const handleAgreementChange = (evt: CheckboxChangeEvent) => {
    setHasAgreed(evt.target.checked);
  }

  const handleEmailChange = useCallback((evt: React.ChangeEvent<HTMLInputElement>) => { setEmail(evt.target.value); }, []);

  const sendRequest = () => {
    dispatch(memberAppointmentActions.get({ memberEmail: email, appointmentId }));
  };

  const formSubmit = (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    sendRequest();
  };

  const renderError = () => {
    const { error } = memberAppointment;
    if (error) {
      let description = '';
      switch (error) {
        case 'Unauthorized!':
          description = 'Wrong Email!';
          break;
        default:
          description = error;
          break;
      }
      return (
        <Alert message={description} type="error" showIcon />
      );
    }
    return null;
  };

  if (!appointmentId) {
    return (
      <Container center>
        <Message><h1>Invalid link!</h1></Message>
      </Container>
    );
  }
  return (
    <Container center>
      <div className="member-page-container">
        <div className="member-page-logo"><img src={logo} alt="Quest HealthConnect" /></div>
        <div className="member-page-error">{renderError()}</div>
        <h2>Welcome To Quest Health Connect. Please enter your email to join the video conference.</h2>
        <p>Note: You can join the room 5 minutes before the appointment</p>
        <form onSubmit={formSubmit}>
          <div><textarea value={agreement} className="member-page-agreement" disabled /></div>
          <div><Checkbox checked={hasAgreed} onChange={handleAgreementChange}>{acknowledgement}</Checkbox></div>
          <div>Email: <Input value={email} onChange={handleEmailChange} placeholder="example@example.com" ref={inputRef} /></div>
          <div><Button className="button submit-button" loading={memberAppointment.loading} onClick={sendRequest} disabled={!hasAgreed}>Submit</Button></div>
        </form>
      </div>
    </Container>
  );
};

import { createReducer } from '@reduxjs/toolkit';
import { authActions, authEpicActions } from './actions';
import { ILoginSucc } from './types';

export type IAuthState = {
  loading: boolean;
  data?: ILoginSucc;
  error?: string;
};
const initialState: IAuthState = {
  loading: false,
  data: undefined,
  error: undefined,
};

export const authReducer = createReducer(initialState, builder =>
  builder
    // check login
    .addCase(authActions.checkLogin, (state, action) => ({ loading: true, data: state.data, error: undefined }))
    // login
    .addCase(authActions.login, (state, action) => ({ loading: true, data: undefined, error: undefined }))
    .addCase(authEpicActions.loginSucc, (state, action) => ({ loading: false, data: action.payload, error: undefined }))
    .addCase(authEpicActions.loginFail, (state, action) => ({ loading: false, data: undefined, error: action.payload.error }))
    // logout
    .addCase(authActions.logout, (state, action) => ({ loading: true, data: state.data, error: undefined }))
    .addCase(authEpicActions.logoutSucc, (state, action) => ({ loading: false, data: undefined, error: undefined }))
    .addCase(authEpicActions.logoutFail, (state, action) => ({ loading: false, data: state.data, error: action.payload.error }))
);

import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { providersActions } from '../../Store/Providers/actions';
import { RootState } from '../../Store';
import { Loading, Select, Option } from 'primitive-comps';
import { Message } from '../Message';
import { map } from 'lodash';
import { getName } from '../Util/name';

interface IProps {
  value?: string;
  onChange: (value: string) => void;
}
export const ProvidersSelect: FC<IProps> = ({ value, onChange }) => {
  const dispatch = useDispatch();
  const providers = useSelector((state: RootState) => state.providers);

  useEffect(() => {
    dispatch(providersActions.getProviders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (providers.loading) {
    return <Loading loading />;
  }
  if (providers.error) {
    return <Message>{providers.error}</Message>;
  }
  if (!providers.data) {
    return <Message>There are no providers!</Message>;
  }
  return (
    <Select style={{ width: 200 }} value={value} onChange={onChange} >
      {map(providers.data.providers, (p, providerId) => (
        <Option key={providerId} value={providerId}>
          {getName({ firstName: p.firstname, lastName: p.lastname })}
        </Option>
      ))}
    </Select>
  );
};
import React, { FC, useCallback } from 'react';
import { Vidyo } from '../../Components/Vidyo';
import { useRouter } from '../../Hooks';
import { ICallLobyInfo } from '../../Models/call';
import { useDispatch } from 'react-redux';
import { joinOrLeaveActions } from '../../Store/JoinLeaveAppointment/actions';

export const Call: FC = () => {
  const dispatch = useDispatch();
  const { query, history } = useRouter<{}, ICallLobyInfo>();
  const handleClose = useCallback(() => history.goBack(), [history]);
  const handleJoin = useCallback(() => {
    if (query.isMember === 'true') {
      console.log(query);
      if (query.memberId) {
        dispatch(joinOrLeaveActions.sendMember({
          appointmentId: query.appointmentId,
          memberId: query.memberId,
          joined: true,
          timestamp: Date.now(),
        }));
      }
    } else {
      dispatch(joinOrLeaveActions.sendProvider({
        appointmentId: query.appointmentId,
        joined: true,
        timestamp: Date.now(),
      }));
    }
  }, [dispatch, query]);

  const handleLeave = useCallback(() => {
    if (query.isMember === 'true') {
      if (query.memberId) {
        dispatch(joinOrLeaveActions.sendMember({
          appointmentId: query.appointmentId,
          memberId: query.memberId,
          joined: false,
          timestamp: Date.now(),
        }));
      }
    } else {
      dispatch(joinOrLeaveActions.sendProvider({
        appointmentId: query.appointmentId,
        joined: false,
        timestamp: Date.now(),
      }));
    }
  }, [dispatch, query]);

  return query
    ? <Vidyo
      displayName={query.displayName}
      token={query.token}
      resourceId={query.resourceId}
      onClose={handleClose}
      onJoin={handleJoin}
      onLeave={handleLeave}
    />
    : null;
};
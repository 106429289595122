import { useState, useCallback } from "react";
import { map } from 'rxjs/operators';
import { ajax } from 'rxjs/ajax';

interface IOptions {
  url: string;
  body?: any;
  headers?: { [key: string]: string };
}
export const useAjaxPost = <T extends {} = {}>(options: IOptions) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error>();
  const [data, setData] = useState<T>();

  const send = useCallback(() => {
    setIsLoading(true);
    setError(undefined);
    setData(undefined);
    ajax.post(options.url, options.body, options.headers).pipe(
      map(({ response }) => response as T),
    ).subscribe(
      (data: T) => {
        setData(data);
        setIsLoading(false);
      },
      (error: Error) => {
        setError(error);
        setIsLoading(false);
      }
    );
  }, [options]);

  return { isLoading, error, data, send };
};

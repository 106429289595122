import { useMemo } from "react";
import { parse } from 'query-string';
import { useParams, useLocation, useHistory, useRouteMatch } from "react-router-dom";

export function useRouter<P extends {} = {}, Q extends {} = {}>() {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();
  const match = useRouteMatch();

  return useMemo(() => {
    return {
      push: history.push,
      replace: history.replace,
      pathname: location.pathname,
      params: params as P,
      // Merge params and parsed query string into single "query" object
      // so that they can be used interchangeably.
      // Example: /:topic?sort=popular -> { topic: "react", sort: "popular" }
      query: parse(location.search) as Q, // Convert string to object
      // Include match, location, history objects so we have
      // access to extra React Router functionality if needed.
      match,
      location,
      history
    };
  }, [params, match, location, history]);
}
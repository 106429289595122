// tslint:disable:no-any
export interface Action<T = any> {
  type: T
}
export interface ActionWithPayload<T extends string, P> extends Action<T> {
  payload: P;
}
export type Nullable<T> = T | null;

type FunctionType = (...args: any[]) => any;
interface ActionCreatorsMapObject { [actionCreator: string]: FunctionType; }
export type ActionsUnion<A extends ActionCreatorsMapObject> = ReturnType<A[keyof A]>;

export function createAction<T extends string>(type: T): Action<T>;
export function createAction<T extends string, P>(type: T, payload: P): ActionWithPayload<T, P>;
export function createAction<T extends string, P>(type: T, payload?: P) {
  return payload === undefined ? { type } : { type, payload };
}

export type Parameter<T extends (...args: any[]) => any> = Parameters<T>[0];

export const doNothing = (payload?: string) => createAction('DO_NOTHING', payload);
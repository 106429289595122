import React, { FC } from 'react';
import './chat.css';

interface IProps {
  user: string;
  isSelf: boolean;
  showSenderName: boolean;
  message: string;
}
export const Chat: FC<IProps> = ({ user, isSelf, message, showSenderName }) => {
  return (
    <div className={"vidyo-chat-text-container" + (isSelf ? ' mine' : '')}>
      <div className="vidyo-chat-sender-and-text-box">
        {showSenderName && <span className="vidyo-chat-sender">{user}</span>}
        <p className={'vidyo-chat-text' + (isSelf ? ' mine' : '')}>
          {message}
        </p>
      </div>
    </div>
  );
};
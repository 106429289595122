import * as React from 'react';
import './styles.css';

interface Props {
  showModal: boolean;
  onClose?: () => void;
  style?: React.CSSProperties;
}
interface State {
  showModal: boolean;
  display: boolean;
}
export class MyModal extends React.Component<Props, State> {
  state: State = {
    showModal: false,
    display: false,
  };


  componentDidMount() {
    const { showModal } = this.props;
    this.setState({ showModal, display: showModal });
    const element = document.getElementById('outside-modal');
    if (element) {
      element.addEventListener('click', this.closeOnOutsideClick);
    }
  }

  componentWillUnmount() {
    const element = document.getElementById('outside-modal');
    if (element) {
      element.removeEventListener('click', this.closeOnOutsideClick);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { showModal } = this.props;
    if (showModal !== prevProps.showModal) {
      if (!showModal) {
        this.setState({ showModal });
        setTimeout(() => this.setState({ display: false }), 200);
      } else {
        this.setState({ display: true });
        setTimeout(() => this.setState({ showModal: true }), 10);
      }
    }
  }

  closeOnOutsideClick = (evt: MouseEvent) => {
    // @ts-ignore
    if (evt.target.id === 'outside-modal') {
      const { onClose = () => { /* */ } } = this.props;
      onClose();
    }
  }

  render() {
    const { showModal, display } = this.state;
    if (!display) { return null; }
    const { onClose } = this.props;
    return (
      <section id="outside-modal" className="modal-container">
        <div className={'modal' + (showModal ? ' show' : '')} style={this.props.style}>
          {onClose && <div style={{ position: 'absolute', top: 15, right: 15, cursor: 'pointer' }} onClick={onClose}>&#10005;</div>}
          {this.props.children}
        </div>
      </section>
    );
  }
}
export interface EmailRequestPayload {
  ToAddress: string[];
  Subject: string;
  LastName: string;
  Content: string;
  TextFormat: 'html' | 'plain';
  FromAddress: string | null;
}

export type EmailRequestResponse = Array<{
  status: number;
  message: string;
}>;

export function createEmailTemplate(link: string) {
  return `
  <!DOCTYPE html
  PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
  
  <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml"
    xmlns:o="urn:schemas-microsoft-com:office:office">
  
  <head>
    <!--[if gte mso 9]><xml>
              <o:OfficeDocumentSettings>
                <o:AllowPNG/>
                <o:PixelsPerInch>96</o:PixelsPerInch>
              </o:OfficeDocumentSettings>
            </xml><![endif]-->
    <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
    <title>Quest Diagnostics</title>
  </head>
  
  <body>
    <table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%" align="center"
      style="width: 100%; margin: 0; padding: 0;">
      <tr>
        <td>
          Hello,
        </td>
      <tr>
      <tr>
        <td>&nbsp;</td>
      <tr>
      <tr>
        <td>here is the link to your appointment: <a href="${link}">${link}</a></td>
      <tr>
    </table>
  </body>
  
  </html>
  `;
} 
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { FetchConfig } from './Components/FetchConfig';
import { Config } from './config';
import './index.css';
import { configureStore } from './Store';
import { App } from './App';
import * as firebase from 'firebase/app';
import 'firebase/auth';

const store = configureStore();
ReactDOM.render(
  <FetchConfig>
    {(config) => {
      Config.initialize(config);
      if (!firebase.apps.length) {
        firebase.initializeApp(Config.FirebaseAuth);
      }
      return (
        <Provider store={store}>
          <App />
        </Provider>
      );
    }}
  </FetchConfig>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import React, { FC, useCallback } from 'react';
import { Button } from 'primitive-comps';
import { TTimeline } from '../Util/timeline';

interface IProps {
  name: string;
  timelines: TTimeline[];
}
export const CsvTimelineButton: FC<IProps> = ({ timelines, name }) => {
  const handleClick = useCallback(() => {
    var csv = 'Service ID,Appiontment ID, Scheduled Appointment Date and Time, JOIN/LEAVE Date and Time, Name, Action\n';
    timelines.forEach((row) => {
      csv += row.join(',');
      csv += "\n";
    });

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = `${name}_report.csv`;
    hiddenElement.click();
  }, [timelines, name]);

  return (
    <Button className="button" onClick={handleClick}>Download Report</Button>
  );
};
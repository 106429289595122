import { createReducer } from '@reduxjs/toolkit';
import { generateRoomActions, generateRoomEpicActions } from './actions';
import { IGetGenerateRoomSucc } from './types';

export interface IGetGenerateRoomState {
  loading: boolean;
  data?: IGetGenerateRoomSucc;
  error?: string;
}

const initialState: IGetGenerateRoomState = {
  loading: false,
  data: undefined,
  error: undefined,
}

export const generateRoomReducer = createReducer(initialState, builder =>
  builder
    // GET
    .addCase(generateRoomActions.get, (state, action) => ({ loading: true, data: undefined, error: undefined }))
    .addCase(generateRoomEpicActions.getSucc, (state, action) => ({ loading: false, data: action.payload, error: undefined }))
    .addCase(generateRoomEpicActions.getFail, (state, action) => ({ loading: false, data: undefined, error: action.payload.error }))
);

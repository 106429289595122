import React, { FC, useEffect, useState } from 'react';
import { MyModal } from '../Modal';
import { ajax } from 'rxjs/ajax';
import { map } from 'rxjs/operators';
import { Conf } from '../../config';
import { Loading } from '../Loading';

interface IProps {
  children: (configs: Conf) => React.ReactNode;
}
/**
 * Fetched the config.json file and passess it to it's child
 */
export const FetchConfig: FC<IProps> = ({ children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [configs, setConfigs] = useState<Conf>();
  const [error, setError] = useState<string>();

  useEffect(() => {
    fetch();
  }, []);

  const fetch = () => {
    setIsLoading(true);
    ajax.get('/config.json').pipe(
      map(({ response }) => response),
    ).subscribe(
      (configs: Conf) => {
        setIsLoading(false);
        setConfigs(configs);
      },
      (error: Error) => {
        setIsLoading(false);
        setError(error.message || 'Unknown Error!');
      }
    );
  };

  const getMessage = () => {
    if (error) {
      return <div>Something went wrong while fetching the config file! Please refresh the page.</div>;
    }
    if (!configs) {
      return <div>Config file doesn't have the config information! Please refresh the page.</div>;
    }
    return null;
  };

  if (isLoading) {
    return <Loading center />;
  }
  if (configs) {
    return <>{children(configs)}</>;
  }
  return (
    <MyModal showModal style={{ width: 500, height: 200, }}>
      <div style={{ fontSize: '1.3em', padding: 10, display: 'flex', justifyContent: 'space-between', flexDirection: 'column', height: '100%' }}>
        {getMessage()}
      </div>
    </MyModal>
  );
};
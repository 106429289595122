import React, { FC, useState, useRef } from 'react';
import { useEventListener } from '../../Hooks';
import './styles.css';

interface IProps {

}
export const Dropdown: FC<IProps> = ({ children }) => {
  const [show, setShow] = useState(false);
  const ref = useRef<HTMLDivElement>(null);
  const handleClick = (evt: MouseEvent) => {
    // @ts-ignore
    if (ref.current && evt.target && (!evt.target.className || !evt.target.className.includes || !evt.target.className.includes('dropbtn'))) {
      // @ts-ignore
      if (show && !ref.current.contains(evt.target)) {
        setShow(false);
      }
    }
  };

  useEventListener('mousedown', handleClick, document);

  return (
    <div className="dropdown">
      <button onClick={() => setShow(!show)} className="dropbtn">&#9776;</button>
      <div ref={ref} className={'dropdown-content' + (show ? ' show' : '')}>
        {children}
      </div>
    </div>
  );
};

interface IDropdownItemProps {
  onClick?: () => void;
}
export const DropdownItem: FC<IDropdownItemProps> = ({ children, onClick }) => {
  return (
    <a href="/#" onClick={onClick}>{children}</a>
  );
};
import React, { FC, CSSProperties, ReactNode } from 'react';
import './styles.css';

interface IProps {
  title?: ReactNode;
  style?: CSSProperties;
}
export const PageContainer: FC<IProps> = ({ children, style, title }) => {
  return (
    <div className="page-container" style={style}>
      {typeof title === 'string' ? <h1 className="page-title">{title}</h1> : <div className="page-title">{title}</div>}
      <div className="page-content">
        {children}
      </div>
    </div>
  );
};